<template>
    <section>

        <div v-if="historyOption === 'addPaquete'">
            <addPaquete />
        </div>
        <div v-if="historyOption === 'editPaquete'">
            <editPaquete />
        </div>
        <section v-if="historyOption === 'Default'">
            <div class="contBox">
                <div class="tableCont">
                    <h3>Paquetes de servidor</h3>

                    <div class="action">
                        <button @click="changeView('addPaquete','')">
                            <p>Añadir paquete</p> <img src="../../assets/Subtract.svg" alt="">
                        </button>
                    </div>

                    <div class="table">

                        <div class="tableHeader">
                            <div class="headItem G">
                                <p>Nombre del paquete</p>
                            </div>
                            <div class="headItem M">
                                <p>Periodo de tiempo</p>
                            </div>
                            <div class="headItem S">
                                <p>Espacio (Mb)</p>
                            </div>
                            <div class="headItem G">
                                <p>Ancho de banda (Mb)</p>
                            </div>
                            <div class="headItem XS">
                                <p>Precio</p>
                            </div>
                            <div class="headItem Utils">
                                <p>Editar</p>
                            </div>
                            <div class="headItem Utils">
                                <p>Borrar</p>
                            </div>
                        </div>
                        <div v-if="filteredItems.length>0">
                            <div  class="tableLine" v-for="(paquete, index) in filteredItems" :key="index">
                            <div class="lineItem G">
                                <p>{{ paquete.nombre }}</p>
                            </div>
                            <div class="lineItem M">
                                <p>{{ paquete.periodo }}</p>
                            </div>
                            <div class="lineItem S">
                                <p>{{ paquete.espacio }}</p>
                            </div>
                            <div class="lineItem G">
                                <p>{{ paquete.ancho_banda }}</p>
                            </div>
                            <div class="lineItem XS">
                                <p>{{ paquete.precio }}</p>
                            </div>
                            <div class="lineItem UtilsUse">
                                <img @click="changeView('editPaquete', paquete)" src="../../assets/stylus.svg" alt="">
                            </div>
                            <div @click="viewModal = true, itemDel=paquete.id" class="lineItem UtilsUse">
                                <img src="../../assets/delete.svg" alt="">
                            </div>
                        </div>
                        </div>
                       
                        <div v-else class="tableLine">
                            <h1 v-if="!loading"> No hay registros aun </h1>
                            <p v-else>
                                Cargando...  
                            </p>
                        </div>

                    </div>
                    
                    <div >
                        <div  id="navigate-buttons" class="mlf2">
                    <div class="dflx">
                        <button @click="prev" id="btn-prev" class="btn-navigate  "><img src="../../assets/Left.png" alt="icono anterior"></button>
                        <p class="num-pages">{{currentPage}} / {{totalPages}}</p>
                        <button @click="next"  id="btn-next" class="btn-navigate  "><img src="../../assets/Right.png" alt="icono siguiente"></button>
                     </div>
        </div>

                    </div>
                   
                </div>
            </div>
        </section>

        <div v-if="viewModal" class="modal_view">
           
           <div id="modal_delete">
               <div class="body">
                    <img src="../../assets/alert.png" alt="icono alerta">
           <h3 >¿Estás seguro de  <span>eliminar <br> este paquete?</span></h3>
               </div>
          
           <div class="modal_del_btns dflx">
               <p @click="eliminar()">Aceptar</p>   <p  @click="closeModal()" >Cancelar</p>
           </div>
         </div>
       </div> 


    </section>
</template>

<script>
import {mapActions} from 'vuex';
import addPaquete from './addPaquete.vue'
import editPaquete from './editPaquete.vue'

export default {
    name: 'Servidores',
    components: {
        addPaquete,
        editPaquete,
    },
    data() {
        return {
            viewModal: false,
            itemDel:'',
            //paginacion
            currentPage: 1,
            itemsPerPage:   2,
        }
    },
    async created() {
        const response = await this.getAllInfoPqt('paquete');
    },
    computed:{
        loading(){
            return this.$store.getters["paquete/loading"];
        },

        historyOption(){
            return this.$store.getters["servidores/getHistoryOption"]
        },
        data(){
            const data = this.$store.getters["paquete/data"]
            if(!Array.isArray(data)) return []
            return data;
        },

          //Paginacion
          allItems () {
            return Object.keys(this.data).map(pid => this.data[pid])
        },
        filteredItems () {
        
             return  this.allItems.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage )
        },
        totalPages () {
            let total = Math.ceil(this.allItems.length / this.itemsPerPage)
                if(total < this.currentPage){
                this.currentPage =1
                }
        return total
       },
    },
    methods:{
        ...mapActions('servidores', ['setHistoryOptionSrv']),
        ...mapActions('paquete', ['getAllInfoPqt', 'setAddedPqt']),
        wait: function () {
            setTimeout(() => this.$router.go(), 200);
        },

        changeView(option, item){
             this.item=item
             this.setAddedPqt(item)
             this.setHistoryOptionSrv(option)
         },
        
         closeModal:function(){
             this.viewModal=false;
             this.itemDel =''
         },

         eliminar: async function(){
          
            await this.$store.dispatch("paquete/deleteItemPqt", {option: 'paquete', id:this.itemDel});
            this.wait()
         },

           // botones de paginacion.
        next () {
          if ( this.currentPage < this.totalPages) {
            this.currentPage++
          }
        },
        prev () {
          if (this.currentPage > 1) {
            this.currentPage--
          }
        },

    }
}
</script>

<style scoped>

p.num-pages{
    display: flex;
    align-items: center;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    width: 90%;
    height: 40vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.contBox {
    display: flex;
    padding: 1.7187500000000002VW 1.9791666666666665VW;
    flex-direction: column;

    border-radius: 1.25VW;
    border: 0.5px solid #E6E6E6;
    background: #FFF;
}

.tableCont {
    display: flex;
    flex-direction: column;
    gap: 0.625VW;
}

.tableCont h3 {
    color: #747475;

    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table {
    overflow: clip;
    width: min-content;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    gap: 0.1vw;
    height: 2.45vw;
    border-radius: 2vw 0.5vw 0.5vw 2vw;
    overflow: clip;

}

.tableLine {
    display: flex;
    flex-direction: row;
    gap: 0.1vw;
    height: 2.45vw;
    border-bottom: #a7a7a7 0.1vw solid;
}

.headItem {
    background: var(--color-2);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.7208333333333333VW 0.8333333333333334VW;

}

.lineItem {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0.7208333333333333VW 0.8333333333333334VW;
}

.headItem img {
    padding: 0.2vw;
    height: 1.30520833333334VW;
    ;
    width: 1.30520833333334VW;
}

.headItem img:hover {
    background-color: #5125cc83;
    cursor: pointer;
    border-radius: 5vw;
}

.headItem p {
    color: var(--color-5);
    font-size: 0.6591666666666666VW;
    font-weight: 500;
}

.lineItem p {
    overflow: hidden;

    color: #747475;
    font-size: 0.6591666666666666VW;
    font-weight: 500;
    text-overflow: ellipsis;

}

.lineItem img {
    background-color: var(--color-2);

    padding: 0.3vw;
    width: 1.40520833333334VW;
    height: 1.40520833333334VW;
    border-radius: 5vw;
}

.lineItem img:hover {
    background-color: #5018E8;
    cursor: pointer;
    transform: scale(1.1);
}

.action button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12vw;
    padding: 0.7vw 1.2vw;
    border-radius: 50vw;
    border: none;
    cursor: pointer;
    background: var(--color-1);
}

.action button p {
    color: #FFF;

    font-size: 0.725VW;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.G {
    width: 12.104166666666666VW;
}

.M {
    width: 10.889583333333334VW;
}

.S {
    width: 8.958333333333334VW;
}

.XS {
    width: 6.508333333333334VW;
}

.Utils {
    justify-content: center;
    padding: inherit 0;
    width: 4.0208333333333335VW;
    background-color: var(--color-8);
}

.Utils p {
    font-size: 0.7166666666666667VW;

}

.UtilsUse {
    justify-content: center;
    padding: inherit 0;
    width: 4.0208333333333335VW;


}
</style>./addPaquete.vue