<template >
    <div id="AddVdo">
       
        <div class="form-content">
            <form @submit.prevent="
                submit(
                 title , datevideo, url, file1, id_category
                )
              ">
    

                    <div class="grup-form dflx">
                        <div class="boxform">
                            <label for="name" class="name">Titulo </label>
                            <input v-model="title" type="text" id="name" name="name"  required class="in1">
                        </div>
                         <div class="boxform">
                            <label for="dateblog" class="dateblog">Fecha </label>
                            <input v-model="datevideo" type="date" id="dateblog" name="dateblog"   required >
                        </div>

                        <div class="grup-form dflx">
                            <div class="boxform">
                                <label >Imagen</label>
                            <div class="dflx">
                                <label class="filelabel" for="uploadimg"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg">   {{archivo}}</label>
                            </div> 
                                
                                <input @change="previewFiles2" type="file" id="uploadimg" name="uploadimg"    required class="fileinput">
                            </div>
                        
                        </div>


                        
                        
                        
                    </div>
                    
                    <div class="grup-form dflx">
                
                        <div class="boxform">
                            <label for="video" class="video">Insertar iFrame</label>
                            <input v-model="url" type="text" id="video" name="video">
                        </div>

                        <div class="boxform">

                            <label for="id_category" class="id_category">Categoría </label>
                          
                              <div class="select-container">
                                <input
                                    type="checkbox"
                                    name="select-ch3"
                                    id="select-ch3"
                                    v-model="active3"
                                    :true-value="true"
                                    :false-value="false"
                                />
                                <label for="select-ch3">
                                    <div id="select-role" :class="{ rounded: active3 }">
                                    {{catname }}
                                    </div>
                                </label>
                                <div  class="options-cont" @click="closeSelect3()">
                                    <div v-for="(cat, key) in categorias" :key='key' class="option-btn" @click="id_category = cat.id, catname =cat.name">{{cat.name}}</div>
                                </div>
                               
                                </div>
                        </div>
                    </div>
                   
                   
                    <label for="about" class="about">Video </label>
             <vue-editor 
                 id="editor"
                 class="quill-editor-about"
                :editorToolbar="toolbar()"
                v-model="url"
                >
              </vue-editor> 

                  



                    
                        <div class="dflx">
                               <p @click="wait()" class="btnRegresar">Regresar</p>
                                  <button class="alta" type="submit">Guardar </button>
                        </div>
                     

                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>
     


        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
                <div class="body dflx">
                     
                           <h3>Video agregado</h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">Aceptar</p> <p  @click="toEdit()" class="editar">Editar</p>  <p  @click="back()" class="inicio">Inicio</p>
            </div>
          </div>
        </div> 

        </div>
           
    </div>
</template>
<script>
import {mapActions} from 'vuex';
import { VueEditor } from "vue2-editor";

export default {
    name:'AddVdo',
    components: {
         VueEditor,
    },
    data(){
        return{
            status:'',
            message:'',
            title:"" ,
            datevideo:"" ,
            url:"",
            file1:null,
            archivo:'No se eligió archivo',
            id_category:'',
            catname:'',
          
           active: false,
           active3:false,

           //MODAL

            viewModal:false,



        }
    },
    created(){
             this.getAllInfoVct('videos_category')
    },
    computed:{
  categorias() {
            return this.$store.getters["videos_category/data"];
        },
    },
    methods:{
          ...mapActions('videos_category', ['getAllInfoVct']),
         ...mapActions('videos', ['setAddedVdo']),
         ...mapActions('videos', ['setHistoryOptionVdo']),
          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        submit: async function( title, datevideo, url, image, id_category){
                 this.status =''
                 this.message =''

               var data = new FormData()
                data.append('title', title);
                data.append('datevideo', datevideo);
                     data.append('id_category',id_category);
                data.append('url', url);
                data.append('image', image);
                data.append('_method', 'POST');
              
                  

                  if(image != null){
                    let result = await this.$store.dispatch("videos/addItemVdo",  {option:'videos', item: data});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedVdo(result.added )
                       this.showModal()
                    }             
                  }else{
                      this.status = 'error'
                      this.message = 'Imposible subir imagen'
                  }

            
    },
     closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },

closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },

     showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedVdo('')
        this.setHistoryOptionVdo('Default')
    },
    Add:function(){
        this.status='';
           this.message='';
           this.name="" ;
         
        this.viewModal=false;
        this.setAddedVdo('');
    },
    toEdit:function(){
        
        
        this.setHistoryOptionVdo('Edit')
         this.viewModal=false;
    },
    back:function(){
        setTimeout(() => this.$router.go(), 200);
    },

    toolbar:function(){
             
            return  [
                [ 'video'],
            ];
             
            },
    previewFiles2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file1= files[0];
            this.archivo = this.file1.name
        },


   }
}
</script>
<style scoped>


    
    #AddVdo label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #AddVdo input, #AddVdo select{
        height: 2.5VW;
        width: 13.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw 1.5vw 1.5vw 0vw;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
        padding-right: 1vw;
        margin-right:2vw;
    }


#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}#select-ch:checked ~ .options-cont {
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 12.3vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}#select-ch:checked ~ .options-cont , #select-ch2:checked ~ .options-cont, #select-ch3:checked ~ .options-cont , #select-ch4:checked ~ .options-cont , #select-ch5:checked ~ .options-cont  , #select-ch6:checked ~ .options-cont , #select-ch7:checked ~ .options-cont{
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 13.32vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}








</style>