<template>
    <section>

        <div v-if="historyOption === 'addClient'">
            <addCliente />
        </div>
        <div v-if="historyOption === 'editClient'">
            <editCliente />
        </div>
        <section v-if="historyOption === 'Default'">

            <div class="contBox">
                <div class="tableCont">
                    <h3>Lista de clientes</h3>
                    <div class="action">
                        <button @click="changeView('addClient','')">
                            <p>Añadir cliente</p> <img src="../../assets/Subtract.svg" alt="">
                        </button>
                    </div>
                    <div class="table">

                        <div class="tableHeader">
                            <div class="headItem G">
                                <p>Nombre</p>
                            </div>
                            <div class="headItem M">
                                <p>Paquete</p>
                            </div>
                            <div class="headItem S">
                                <p>Estatus</p>
                            </div>

                            <div class="headItem G">
                                <p>Correo</p>
                            </div>
                            <div class="headItem XS">
                                <p>Termina en</p>
                            </div>
                            <div class="headItem Utils">
                                <p>Editar</p>
                            </div>
                            <div class="headItem Utils">
                                <p>Borrar</p>
                            </div>
                        </div>
                        <div class="tableLine" v-for="(clients, index) in data" :key="index">
                            <div class="lineItem G">
                                <p>{{ clients.nombre }}</p>
                            </div>
                            <div class="lineItem M">
                                <p v-if="clients.paquete?.nombre">{{ clients.paquete.nombre }}</p>
                                <p v-else> - </p>
                            </div>

                            <div class="lineItem S">
                                <p>{{ clients.estatus }}</p>
                            </div>
                            <div class="lineItem G">
                                <p>{{ clients.correo }}</p>
                            </div>
                            <div class="lineItem XS">
                                <p>{{ clients.expira }}</p>
                            </div>
                            <div @click="changeView('editClient', clients)" class="lineItem UtilsUse">
                                <img src="../../assets/stylus.svg" alt="">
                            </div>
                            <div @click="viewModal = true, itemDel = clients.id" class="lineItem UtilsUse">
                                <img src="../../assets/delete.svg" alt="">
                            </div>
                        </div>

                    </div>
                    <div class="pagination">

                    </div>
                    
                </div>
            </div>
        </section>

        <div v-if="viewModal" class="modal_view">
           
           <div id="modal_delete">
               <div class="body">
                    <img src="../../assets/alert.png" alt="icono alerta">
           <h3 >¿Estás seguro de  <span>eliminar <br> este Cliente?</span></h3>
               </div>
          
           <div class="modal_del_btns dflx">
               <p @click="eliminar()">Aceptar</p>   <p  @click="closeModal()" >Cancelar</p>
           </div>
         </div>
       </div> 


    </section>
</template>

<script>
import {mapActions} from 'vuex';
import addCliente from './addCliente.vue'
import editCliente from './editCliente.vue'

export default {
    name: 'Clientes',
    components: {
        addCliente,
        editCliente,
    },
    data() {
        return {
            viewModal: false,
            itemDel: "",
        }
    },
    async created() {
        const response = await this.getAllInfoCli('clients');
        console.log(response);
    },
    computed:{
        historyOption(){
            return this.$store.getters["clients/getHistoryOption"]
        },
        data(){
            const data = this.$store.getters["clients/data"]
            if(!Array.isArray(data)) return []
            return data;
        },
    },
    methods:{
        ...mapActions('clients', ['setHistoryOptionCli', 'setAddedCli', 'getAllInfoCli']),


        changeView(option, item){
             this.item=item
             this.setAddedCli(item)
             this.setHistoryOptionCli(option)
        },
        closeModal:function(){
            this.viewModal=false;
            this.itemDel =''
        },
        eliminar: async function(){
        await this.$store.dispatch("paquete/deleteItemPqt", {option: 'clients', id:this.itemDel});
            this.wait()
        },
        wait: function () {
            setTimeout(() => this.$router.go(), 200);
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

section {
    width: 90%;
    height: 40vw;
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.contBox {
    display: flex;
    padding: 1.7187500000000002VW 1.9791666666666665VW;
    flex-direction: column;

    border-radius: 1.25VW;
    border: 0.5px solid #E6E6E6;
    background: #FFF;
}

.tableCont {
    display: flex;
    flex-direction: column;
    gap: 0.625VW;
}

.tableCont h3 {
    color: #747475;

    font-size: 0.9375VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.table {
    overflow: clip;
    width: min-content;
}

.tableHeader {
    display: flex;
    flex-direction: row;
    gap: 0.1vw;
    height: 2.45vw;
    border-radius: 2vw 0.5vw 0.5vw 2vw;
    overflow: clip;

}

.tableLine {
    display: flex;
    flex-direction: row;
    gap: 0.1vw;
    height: 2.45vw;
    border-bottom: #a7a7a7 0.1vw solid;
}

.headItem {
    background: var(--color-2);
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.7208333333333333VW 0.8333333333333334VW;

}

.lineItem {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0.7208333333333333VW 0.8333333333333334VW;
}

.headItem img {
    padding: 0.2vw;
    height: 1.30520833333334VW;
    ;
    width: 1.30520833333334VW;
}

.headItem img:hover {
    background-color: #5125cc83;
    cursor: pointer;
    border-radius: 5vw;
}

.headItem p {
    color: var(--color-5);
    font-size: 0.6591666666666666VW;
    font-weight: 500;
}

.lineItem p {
    overflow: hidden;

    color: #747475;
    font-size: 0.6591666666666666VW;
    font-weight: 500;
    text-overflow: ellipsis;

}

.lineItem img {
    background-color: var(--color-2);

    padding: 0.3vw;
    width: 1.40520833333334VW;
    height: 1.40520833333334VW;
    border-radius: 5vw;
}

.lineItem img:hover {
    background-color: #5018E8;
    cursor: pointer;
    transform: scale(1.1);
}

.action button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12vw;
    padding: 0.7vw 1.2vw;
    border-radius: 50vw;
    border: none;
    cursor: pointer;
    background: var(--color-1);
}

.action button p {
    color: #FFF;

    font-size: 0.725VW;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.G {
    width: 12.104166666666666VW;
}

.M {
    width: 10.889583333333334VW;
}

.S {
    width: 8.958333333333334VW;
}

.XS {
    width: 6.508333333333334VW;
}

.Utils {
    justify-content: center;
    padding: inherit 0;
    width: 4.0208333333333335VW;
    background-color: var(--color-8);
}

.Utils p {
    font-size: 0.7166666666666667VW;

}

.UtilsUse {
    justify-content: center;
    padding: inherit 0;
    width: 4.0208333333333335VW;


}
</style>./addPaquete.vue