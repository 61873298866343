<template>
    <div id="Sections">
         <HeaderSec />
      
         <div   v-if="historyOption == 'Usuarios'">
             <Usuarios/>
         </div>
         <div  v-if="historyOption == 'Default'">
            <Usuarios/>
         </div>
           <div  v-if="historyOption == 'BLOG'">
            <Blog/>
         </div>
         <div  v-if="historyOption == 'BLOGCAT'">
            <BlogCat/>
         </div>
          <div  v-if="historyOption == 'PROJCAT'">
            <ProjCat/>
         </div>
          <div  v-if="historyOption == 'PROJ'">
            <Proj/>
         </div>
          <div  v-if="historyOption == 'TAG'">
            <Tag/>
         </div>
          <div  v-if="historyOption == 'colaboradores'">
            <Colab/>
         </div>
          <div  v-if="historyOption == 'videos'">
            <Videos/>
         </div>
          <div  v-if="historyOption == 'VDOCAT'">
            <Videoscategory/>
         </div>
          <div  v-if="historyOption == 'historias'">
            <Historias/>
         </div>
          <div  v-if="historyOption == 'paquetes'">
            <Servidores/>
         </div>
          <div  v-if="historyOption == 'clientes'">
            <Clientes/>
         </div>
        
    </div>
</template>
<script>

import {mapActions} from 'vuex';

import HeaderSec from '../components/HeaderSec.vue';
import Usuarios from '../views/usuarios/usuarios.vue'
import Blog from '../views/blog/blog.vue'
import Tag from '../views/tag/tag.vue'
import BlogCat from '../views/blog/blog_category.vue'
import ProjCat from '../views/project/project_category.vue'
import Proj from '../views/project/project.vue'
import Colab from  '../views/colaboradores/colaboradores.vue'
import Videos from  '../views/videos/videos.vue'
import Videoscategory from  '../views/videos/videos_category.vue'
import Historias from  '../views/historias/historias.vue'
import Servidores from '../views/servidores/servidores.vue'
import Clientes from '../views/servidores/clientes.vue'
export default {
  name: 'Sections',
components:{
      HeaderSec,
      Usuarios,
      Blog,
      Tag,
      BlogCat,
      Colab,
      ProjCat,
      Proj,
      Videos,
      Videoscategory,
      Historias,
      Servidores,
      Clientes,
  },
 
   data(){
       return{
           search_value:'',
           status:'',
           message:'',
           option:'Default',
       }
   },
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
    },
   created(){
        let option = localStorage.getItem('nav')
        if(option != undefined && option != null && option != ''){
            this.option = option
            this.setHistoryOptionMain(option)
        }
      
   },
   methods:{
        ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('main', ['getHistoryOption']),
   }

   
    
 
}
</script>
<style scoped>
   
   #Sections{
    background: var(--color-5);
     min-height: 59.25VW;
     width: 78.17708333333333VW;
     margin-left: 4.166666666666666VW;
   }

   .proximamente p{
       color: var(--color-1);
       font-weight: 700;
       font-size: 5vw;
       margin-top: 11.5vw;
       margin-left: 15vw;
   }


  



</style>
