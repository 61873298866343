import { render, staticRenderFns } from "./blog_category.vue?vue&type=template&id=4b46f726&scoped=true"
import script from "./blog_category.vue?vue&type=script&lang=js"
export * from "./blog_category.vue?vue&type=script&lang=js"
import style0 from "./blog_category.vue?vue&type=style&index=0&id=4b46f726&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b46f726",
  null
  
)

export default component.exports