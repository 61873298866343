<template >
    <div id="EditVdo">
               
               <div class="form-content">
            <form @submit.prevent="
                submit(
                 added.title,
                 added.datevideo,
                 added.url,
                 file1,                 
                 added.id_category,
                )
              ">
                     <div class="grup-form dflx">
                        <div class="boxform">
                            <label for="name" class="name">Nombre </label>
                            <input v-model="added.title" type="text" id="name" name="name"  required >
                        </div>
                         <div class="boxform">
                            <label for="dateblog" class="dateblog">Fecha </label>
                            <input v-model="added.datevideo" type="date" id="dateblog" name="dateblog"   required >
                        </div>

                        <div class="grup-form dflx">
                            <div class="boxform">
                                <label >Imagen</label>
                            <div class="dflx">
                                <label class="filelabel" for="uploadimg"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg">   {{archivo}}</label>
                            </div> 
                                
                                <input @change="previewFiles" type="file" id="uploadimg" name="uploadimg">
                            </div>
                        
                        </div>
                        
                        
                        
                    </div>
                  
                    <img class="imagen_portada" :src="srcImg(added.image)" alt="Portada">

<div class="boxform">

                            <label for="id_category" class="id_category">Categoría </label>
                          
                              <div class="select-container">
                                <input
                                    type="checkbox"
                                    name="select-ch3"
                                    id="select-ch3"
                                    v-model="active3"
                                    :true-value="true"
                                    :false-value="false"
                                />
                                <label for="select-ch3">
                                    <div id="select-role" :class="{ rounded: active3 }">
                                    {{added.category_name }}
                                    </div>
                                </label>
                                <div  class="options-cont" @click="closeSelect3()">
                                    <div v-for="(cat, key) in categorias" :key='key' class="option-btn" @click="added.id_category = cat.id, added.category_name =cat.name">{{cat.name}}</div>
                                </div>
                               
                                </div>
                        </div>
                   
                   <div class="grup-form dflx">
                
                        <div class="boxform">
                            <label for="video" class="video">Insertar iFrame</label>
                            <input v-model="added.url" type="text" id="video" name="video">
                        </div>
                    </div>
                   
                    <label for="about" class="about">Video </label>
             <vue-editor 
                 id="editor"
                 class="quill-editor-about"
                :editorToolbar="toolbar()"
                v-model="added.url"
                >
              </vue-editor> 
                    
                    
                      <div class="dflx">
                               <p @click="wait()" class="btnRegresar">Regresar</p>
                               <button class="alta" type="submit">Editar </button>
                        </div>

                    
                        <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
                        </div>
            </form>


                     
          


        </div>

                <hr class="hrhd">

                

                

            <div v-if="viewModal" class="modal_view">
           
            <div id="modal_edit">
                <div class="body dflx">
                     
                           <h3>Video actualizado </h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_edit_btns dflx">
                <p @click="wait()" class="otro">Aceptar</p> <!-- <p  @click="back()" class="inicio">Inicio</p>-->
            </div>
          </div>
        </div> 

    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import {mapActions} from 'vuex';
let urlpath = require('../../global/url')
export default {
    name:'EditVdo',
      components: {
        VueEditor,
    },
    data(){
        return{
            status:'',
            message:'',
            status2:'',
            message2:'',
            active: false,
            active3: false,
            urlpath: urlpath,
            statusimg:'success',
            archivo:'No se eligió archivo',

            //MODAL
            file1:null,
            viewModal:false,
          
        }
    },
    created (){
        let payload={
            id:this.added.id,
            option: "videos",
        }
          this.getInfoByIdVdo(payload);
          
         this.getAllInfoVct('videos_category')
    },  
    computed:{
        added(){
            return this.$store.getters["videos/getAdded"]
        },
         data() {
            return this.$store.getters["videos/data"];
        },
         categorias() {
            return this.$store.getters["videos_category/data"];
        },
    },
    methods:{
        ...mapActions('videos', ['setAddedVdo']),
        ...mapActions('videos', ['setHistoryOptionVdo']),
        ...mapActions('videos_category', ['getAllInfoVct']),
        wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        ...mapActions("videos", ["getInfoByIdVdo"]),
            submit: async function(  title, datevideo, url, image, id_category ){
                this.status =''
                this.message =''

                if(image != null){
                var data = new  FormData();
                    data.append('id', this.added.id);
                    data.append('title', title);
                    data.append('datevideo', datevideo);
                    data.append('url', url);
                    data.append('image', image);
                    data.append('id_category', id_category);
                    data.append('_method', 'PUT');

                   

                    let result = await this.$store.dispatch("videos/editItemVdo",  {option:'videos', item: data});
                    
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                        this.showModal()
                          
                    } 
                 }else {
                    let item ={
                        "id":this.added.id,
                        "title":title,
                        "datevideo":datevideo, 
                        "url":url, 
                        "image":image, 
                        "id_category":id_category, 
                    }

                    

                        let result = await this.$store.dispatch("videos/editItemVdo",  {option:'videos', item: item});
                    
                        if(result.status=='error'){
                            this.status='error'
                            this.message= result.message
                        }else{ // success
                            this.showModal()
                            
                        } 
                 }
            
    },
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },
    

     delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000);
           
            
        },
        delMsgs: function(){
            this.status=""
            this.message=""
             this.status2=""
            this.message2=""
        },


      showModal: function(){
         this.viewModal=true
     },
    closeModal:function(){
        this.viewModal=false;
        this.setAddedVdo('')
        this.setHistoryOptionVdo('Default')
    },
    Edit:function(){
        this.viewModal=false;
    },
    back:function(){
       setTimeout(() => this.$router.go(), 200);
    },
    toolbar:function(){
                 return  [
                [ 'video'],                                         // remove formatting button
            ];
             
            },


    previewFiles(e) {
     
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.file1= files[0];
        this.archivo = this.file1.name
        },

          srcImg:function (img){
                let src = `${urlpath.url()}/videos-img/${img}`
                
                this.status_img(img)
                 
                return src
        },

         status_img:async  function (img){
                 let payload ={
                    option:'videos',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                    if(response.data.status=='error'){
                        this.statusimg='error'
                    }else{
                        this.statusimg='success'
                    }
                }catch(err){
                    this.statusimg='error'
                }

               
               
    },

    
    closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },

   }


}
</script>
<style scoped>

  .imagen_portada {
    width: 18vw;
    height: 11vw;
    float: right;
    margin-right: 19vw;
  }
   
    #EditVdo label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #EditVdo input, #EditVdo select{
        height: 2.5VW;
        width: 14.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw  1.5vw 1.5vw  0vw ;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
    }

    #select-role {
  height: 2.5vw;
  width: 13.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}#select-ch:checked ~ .options-cont {
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 12.3vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}
#select-ch:checked ~ .options-cont , #select-ch2:checked ~ .options-cont, #select-ch3:checked ~ .options-cont , #select-ch4:checked ~ .options-cont , #select-ch5:checked ~ .options-cont  , #select-ch6:checked ~ .options-cont , #select-ch7:checked ~ .options-cont{
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 13.32vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}


    
</style>