<template >
    <div id="AddBlo">
       
          <div class="form-content">
            <form @submit.prevent="
                submit(
                    title, descrp, id_category, statusproject , formato,  file1, body,  images, url, color, file2, file3, file4
                )
              ">
    
                    <p class="subtitle">Visibilidad</p>
                   <div class="dflx">
                          <p :class="{statusVisible: statusproject =='VISIBLE'}" class="btnStatus" @click="statusproject='VISIBLE'" >VISIBLE</p><p  :class="{statusVisible: statusproject =='OCULTO'}" class="btnStatus"  @click="statusproject='OCULTO'">OCULTO</p>
                   </div>
                    <p class="subtitle">Formato</p>
                   <div class="dflx">
                          <p :class="{statusVisible: formato =='CORTO'}" class="btnStatus" @click="formato='CORTO'" >CORTO</p><p  :class="{statusVisible: formato =='LARGO'}" class="btnStatus"  @click="formato='LARGO'">LARGO</p>
                   </div>


                    <div class="grup-form dflx">
                         <div class="boxform">
                            <label for="title" class="title">Título </label>
                            <input v-model="title" type="text" id="title" name="title"   required >
                         </div>
                         <div class="boxform">
                            <label for="descrp" class="descrp">Descripción </label>
                            <input v-model="descrp" type="text" id="descrp" name="descrp"   required >
                        </div>
                         <div class="boxform">
                            <label for="url" class="url">Url </label>
                            <input v-model="url" type="text" id="url" name="url"   required >
                         </div>

                        
                       
                       
                    </div>
                    <div class="grup-form dflx">
                         <div class="boxform">
                            <label for="color" class="color">Color del título {{color}}</label>
                            <input v-model="color" type="color" id="color" name="color"   required >
                         </div>
                         
                        
                       
                       
                    </div>
                    
                     <div class="grup-form dflx">
                        <div class="boxform">
                            <label >Portada Pc</label>
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg"> <span>Seleccionar Archivo</span></label>  
                               <label class="filelabelname tbl_item" for="uploadimg">   {{archivo}}</label>
                        </div> 
                            
                            <input @change="previewFiles2" type="file" id="uploadimg" name="uploadimg"    
                            required class="fileinput">
                        </div>
                       
                    </div>
                    
                     <div class="grup-form dflx">
                        <div class="boxform">
                            <label >Portada Movil</label>
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg6"> <span>Seleccionar Archivo</span></label>  
                               <label class="filelabelname tbl_item" for="uploadimg6">   {{archivo4}}</label>
                        </div> 
                            
                            <input @change="previewFiles6" type="file" id="uploadimg6" name="uploadimg6"    required class="fileinput">
                        </div>
                       
                    </div>

                    <div class="grup-form dflx">
                        <div class="boxform">
                            <label >Portada Min</label>
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg5"> <span>Seleccionar Archivo</span></label>  
                               <label class="filelabelname tbl_item" for="uploadimg5">   {{archivo2}}</label>
                        </div> 
                            
                            <input @change="previewFiles4" type="file" id="uploadimg5" name="uploadimg5"    required class="fileinput">
                        </div>
                       
                    </div>

                    
                     <div class="grup-form dflx">
                        <div class="boxform">
                            <label >Portada Home</label>
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg7"> <span>Seleccionar Archivo</span></label>  
                               <label class="filelabelname tbl_item" for="uploadimg7">   {{archivo3}}</label>
                        </div> 
                            
                            <input @change="previewFiles5" type="file" id="uploadimg7" name="uploadimg7"  required    class="fileinput">
                        </div>
                       
                    </div>



                      <div class="grup-form dflx">
         
                       
                         <div class="boxform">
                            <label for="id_category" class="id_category">Categoría </label>
                          
                              <div class="select-container">
                                <input
                                    type="checkbox"
                                    name="select-ch3"
                                    id="select-ch3"
                                    v-model="active3"
                                    :true-value="true"
                                    :false-value="false"
                                />
                                <label for="select-ch3">
                                    <div id="select-role" :class="{ rounded: active3 }">
                                    {{catname }}
                                    </div>
                                </label>
                                <div  class="options-cont" @click="closeSelect3()">
                                    <div v-for="(cat, key) in categorias" :key='key' class="option-btn" @click="id_category = cat.id, catname =cat.name">{{cat.name}}</div>
                                </div>
                               
                                </div>
                        </div>

                       
                       
         
        </div>


       


     

                   
                   <!-- Añadir bloques -->
                 
        <hr class="hrhd">
        <p class="subtitle2">Cuerpo de la entrada</p>

      <div class="opciones-bloque">
            
            <div class="dflx optionsbl">
                

                         <div class="boxform">
                            <label for="role" class="role">Tipo de bloque </label>
                           <div class="select-container">
              <input
                type="checkbox"
                name="select-ch"
                id="select-ch"
                v-model="active"
                :true-value="true"
                :false-value="false"
              />
              <label for="select-ch">
                <div id="select-role" :class="{ rounded: active }">
                  {{ tipo }}
                </div>
              </label>
              <div class="options-cont" @click="closeSelect()">
                <div class="option-btn" @click="tipo = 'texto'">Texto</div>
                 <div class="option-btn" @click="tipo = 'imagen'">Imagen</div>
                  <div class="option-btn" @click="tipo = 'slider'">Slider</div>
                   <div class="option-btn" @click="tipo = 'galeria'">Galeria</div>
                     <div class="option-btn" @click="tipo = 'paleta'">Paleta</div>
                  <div class="option-btn" @click="tipo = 'caja'">Caja</div>
                   <div class="option-btn" @click="tipo = 'titulo_centrado'">Titulo centrado</div>
                    <div class="option-btn" @click="tipo = 'subtitulo_centrado'">Subtítulo centrado</div>
                    <div class="option-btn" @click="tipo = 'reto'">reto</div>
                    
                     <div class="option-btn" @click="tipo = 'video'">Video</div>
              </div>
            </div>
                        </div>


                <div  @click="guardarBloque(tipo)"  class="btn_añadir dflx mtbtn">
                                <p > agregar bloque</p>  <img src="../../assets/add.png" alt="">
               </div>
            </div>   

                  

             <vue-editor v-if="tipo=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto"
                >
              </vue-editor> 

                <div v-if="tipo=='video'">
                     <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="video" class="video">Insertar iFrame</label>
                            <input v-model="video" type="text" id="video" name="video">
                        </div>
                    </div>

                        <vue-editor 
                                id="editor"
                                class="quill-editor"
                                :editorToolbar="toolbar()"
                                v-model="video"
                                >
                            </vue-editor> 
                </div>
              


                    <div class="" v-if="tipo =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">


                     </div>

                     <div class="" v-if="tipo =='slider'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">

                              <p @click="añadirSlider()" class="btnRegresar">Agregar</p>

                               <div  class="dflx">
                                    <img  v-for="(item, index) in slider" :key="index" :src="srcImg(item)" class="imgslider mlf" alt="imagen bloque">
                                </div>
                    </div>

                    <div class="" v-if="tipo =='galeria'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">

                              <p @click="añadirGaleria()" class="btnRegresar">Agregar</p>

                                <div  class="dflx">
                                    <img  v-for="(item, index) in galeria" :key="index" :src="srcImg(item)" class="imgslider mlf" alt="imagen bloque">
                                </div>
                    </div>


                    <div class="" v-if="tipo =='paleta'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                    <div v-if="tipo=='reto'">
                       <div class="grup-form ">
                        <div class="boxform">
                            <label for="reto" class="reto">Titulo</label>
                            <input v-model="reto" type="text" id="reto" name="reto">
                        </div>
                        <div class="boxform">
                          <label for="texto" class="texto">Texto</label>
                          
                            <vue-editor 
                                id="editor"
                                class="quill-editor"
                                :editorToolbar="toolbar()"
                                v-model="parrafo"
                                >
                            </vue-editor> 
                        </div>
                    </div>
                    </div>

                    <div v-if="tipo=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado" class="titulo_centrado">Titulo centrado</label>
                            <input v-model="titulo_centrado" type="text" id="titulo_centrado" name="titulo_centrado">
                        </div>
                       </div>
                    </div>
                     <div v-if="tipo=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado" class="subtitulo_centrado">Subtitulo centrado</label>
                            <input v-model="subtitulo_centrado" type="text" id="subtitulo_centrado" name="subtitulo_centrado">
                        </div>
                       </div>
                    </div>
                
                <div v-if="tipo == 'caja'">
                     <p class="subtitle">Caja 1</p>
                    
                        <div class="dflx btnsgrp">
                          
                            <p class="btnopciones" @click="caja1 = 'texto'">Texto</p>
                            <p class="btnopciones" @click="caja1 = 'imagen'">Imagen</p>
                            <p class="btnopciones" @click="caja1 = 'titulo_centrado'">Título centrado</p>
                            <p class="btnopciones" @click="caja1 = 'subtitulo_centrado'">Subtítulo centrado</p>
                            <p class="btnopciones" @click="caja1 = 'reto'">Reto</p>
                        </div>

                         <vue-editor v-if="caja1=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto"
                >
              </vue-editor> 

              <div v-if="caja1=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado" class="titulo_centrado">Título centrado</label>
                            <input v-model="titulo_centrado" type="text" id="titulo_centrado" name="titulo_centrado">
                        </div>
                    </div>
                    </div>

                     <div v-if="caja1=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado" class="subtitulo_centrado">Subtítulo centrado</label>
                            <input v-model="subtitulo_centrado" type="text" id="subtitulo_centrado" name="subtitulo_centrado">
                        </div>
                    </div>
                    </div>


                    <div v-if="caja1=='reto'">
                       <div class="grup-form ">
                        <div class="boxform">
                            <label for="reto" class="reto">Título</label>
                            <input v-model="reto" type="text" id="reto" name="reto">
                        </div>
                        <div class="boxform">
                          <label for="parrafo" class="parrafo">Texto</label>
                        <vue-editor 
                                id="editor"
                                class="quill-editor"
                                :editorToolbar="toolbar()"
                                v-model="parrafo"
                                >
                            </vue-editor> 
                        </div>
                    </div>
                    </div>

                    <div class="" v-if="caja1 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                    <p class="subtitle mtp">Caja 2</p>

                         <div class="dflx btnsgrp">
                          
                            <p class="btnopciones" @click="caja2 = 'texto'">Texto</p>
                            <p class="btnopciones" @click="caja2 = 'imagen'">Imagen</p>
                            <p class="btnopciones" @click="caja2 = 'titulo_centrado'">Titulo centrado</p>
                             <p class="btnopciones" @click="caja2 = 'subtitulo_centrado'">Subtítulo centrado</p>
                            <p class="btnopciones" @click="caja2 = 'reto'">reto</p>
                        </div>

                         <vue-editor v-if="caja2=='texto'"
                 id="editor"
                 class="quill-editor"
                :editorToolbar="toolbar()"
                v-model="texto2"
                >
              </vue-editor> 
               <div v-if="caja2=='titulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo_centrado2" class="titulo_centrado2">Titulo centrado</label>
                            <input v-model="titulo_centrado2" type="text" id="titulo_centrado2" name="titulo_centrado2">
                        </div>
                    </div>
                    </div>

                    <div v-if="caja2=='subtitulo_centrado'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="subtitulo_centrado2" class="subtitulo_centrado2">Subtítulo centrado</label>
                            <input v-model="subtitulo_centrado2" type="text" id="subtitulo_centrado2" name="subtitulo_centrado2">
                        </div>
                    </div>
                    </div>


                    <div v-if="caja2=='reto'">
                       <div class="grup-form ">
                        <div class="boxform">
                            <label for="reto2" class="reto2">Título</label>
                            <input v-model="reto2" type="text" id="reto2" name="reto2">
                        </div>
                        <div class="boxform">
                          <label for="parrafo2" class="parrafo2">Texto</label>
                           <vue-editor 
                                id="editor"
                                class="quill-editor"
                                :editorToolbar="toolbar()"
                                v-model="parrafo2"
                                >
                            </vue-editor> 
                        </div>
                    </div>
                    </div>
                     <div class="" v-if="caja2 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg3"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg3">   {{name_imagen2}}</label>
                        </div> 
                            
                            <input @change="previewFiles3" type="file" id="uploadimg3" name="uploadimg3"    required class="fileinput">
                    </div>



                </div>


             
              

      
      </div>
                   
        <hr class="hrhd">
        <p class="subtitle2">Bloques agregados</p>
        
        <div class="bloques-cont">
                   <div class="dflx header-bc">
                    <div class="col1">
                        <p>ordenar</p>
                    
                    </div>
                    <div class="col2">
                            <p> Tipo</p>
                            
                    </div>
                    <div class="col3 cl3t">
                            <p> Contenido</p>
                            
                    </div>
                    <div class="col4">
                           <p>Eliminar</p>
                    </div>
                   </div>
                <div v-for="(bloq, key) in body" :key="key" class="bloq-item dflx">
                    <div class="col1">
                        
                        <h2 v-if="key !=0" @click="UpItem(bloq, key)" class="cp">	&#9650;</h2>
                        <h2 v-if="key < (body.length-1)" @click="DownItem(bloq,key)" class="cp">	&#9660;</h2>
                    </div>
                    <div class="col2">
                           <p  v-if="bloq.tipo !='titulo_centrado' && bloq.tipo !='subtitulo_centrado'">{{bloq.tipo}}</p>
                            <p  v-else-if="bloq.tipo =='titulo_centrado'">título <br> centrado</p>
                             <p  v-else-if="bloq.tipo =='subtitulo_centrado'">subtítulo <br> centrado</p>


                            
                    </div>
                    <div class="col3"> <!-- contenido del bloque  -->
                        <div v-if="bloq.tipo =='texto'" v-html="bloq.texto"></div>
                         <div v-if="bloq.tipo =='video'" v-html="bloq.video"></div>
                        <div v-if="bloq.tipo =='reto'">
                            <p> <strong>{{bloq.reto.texto}}</strong> </p>
                            <div v-html=" bloq.reto.parrafo"></div>
                        </div>
                         <div v-if="bloq.tipo =='titulo_centrado'">
                            <p> <strong>{{bloq.titulo_centrado}}</strong> </p>
                        </div>
                           <div v-if="bloq.tipo =='subtitulo_centrado'">
                            <p>{{bloq.subtitulo_centrado}} </p>
                        </div>
                        <img v-if="bloq.tipo =='imagen'" :src="srcImg(bloq.imagen)" alt="imagen bloque">
                        <div  v-if="bloq.tipo =='slider'">
                            <img v-for="(img , key) in bloq.slider" :key="key" :src="srcImg(img)" class="imgslider" alt="imagen bloque">
                        </div>
                          <div  v-if="bloq.tipo =='galeria'">
                            <img v-for="(img , key) in bloq.galeria" :key="key" :src="srcImg(img)" class="imgslider" alt="imagen bloque">
                        </div>
                        <img v-if="bloq.tipo =='paleta'" :src="srcImg(bloq.imagen)" alt="imagen bloque">
                        <div v-if="bloq.tipo =='caja'" class="dflx">
                           
                            <div class="caja1">
                                <p v-if="bloq.caja[0].tipo=='texto'" v-html="bloq.caja[0].texto">  </p>
                                  <img v-if="bloq.caja[0].tipo =='imagen'" :src="srcImg(bloq.caja[0].imagen)" alt="imagen bloque">
                                  <div v-if="bloq.caja[0].tipo =='reto'">
                                    <p> <strong>{{bloq.caja[0].reto.texto}}</strong></p>
                                     <div v-html=" bloq.caja[0].reto.parrafo"></div>
                                </div>
                                 <div v-if="bloq.caja[0].tipo =='titulo_centrado'">
                                    <p> <strong>{{bloq.caja[0].titulo_centrado}}</strong> </p>
                                </div>
                                   <div v-if="bloq.caja[0].tipo =='subtitulo_centrado'">
                                    <p> {{bloq.caja[0].subtitulo_centrado}} </p>
                                </div>
                            </div>
                             <h2 class="cp" @click="CambiarOrdenCaja(bloq, key)">	&#11020;</h2>
                            <div class="caja2">
                                <p v-if="bloq.caja[1].tipo=='texto'" v-html="bloq.caja[1].texto">  </p>
                                  <img v-if="bloq.caja[1].tipo =='imagen'" :src="srcImg(bloq.caja[1].imagen)" alt="imagen bloque">
                                    <div v-if="bloq.caja[1].tipo =='reto'">
                                    <p><strong>{{bloq.caja[1].reto.texto}}</strong></p>
                                      <div v-html=" bloq.caja[1].reto.parrafo"></div>
                                </div>
                                  <div v-if="bloq.caja[1].tipo =='titulo_centrado'">
                                    <p> <strong>{{bloq.caja[1].titulo_centrado}}</strong> </p>
                                </div>
                                  <div v-if="bloq.caja[1].tipo =='subtitulo_centrado'">
                                    <p> {{bloq.caja[1].subtitulo_centrado}} </p>
                                </div>
                            </div>
                        </div>
                            
                    </div>
                    <div class="col4">
                           <h2 class="cp"  @click="DelItem(bloq,key)">&#10006;</h2>
                    </div>
                </div>
                <p class="snu" v-if="body.length ==0">Sin contenido agregado al cuerpo del project</p>
        </div>



                    
                        <div class="dflx">
                                  <p @click="wait()" class="btnRegresar">Regresar</p>
                                  <button class="alta" type="submit">Guardar </button>
                        </div>
                     

                     
            </form>
     


        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
                <div class="body dflx">
                     
                           <h3>Entrada agregada</h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">Aceptar</p> <p  @click="toEdit()" class="editar">Editar</p>  <p  @click="back()" class="inicio">Inicio</p>
            </div>
          </div>
        </div> 

        </div>



         <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
           </div>
<!--
  <p> {{name_imagen}}</p>
     <p>   {{image}}</p>

       <p>{{tipo}}</p>
       <p> {{body}}</p>
     <p> {{body}} </p>
<p> {{galeria}} </p> 
<p> {{images}}</p>
      
-->



  
    </div>
</template>
<script>


import { VueEditor } from "vue2-editor";
import {mapActions} from 'vuex';
let urlpath = require('../../global/url')

export default {
 components: {
  
    VueEditor,
    
  },
    data(){
        return{
             urlpath: urlpath,
            title:"", 
            descrp:"", 
         
            id_category:"", 
            catname:"",
           
            file1:null,
            archivo:'No se eligió archivo',

            color:'#000000',
             file2:null,
            archivo2:'No se eligió archivo',

               file3:null,
            archivo3:'No se eligió archivo',

             file4:null,
            archivo4:'No se eligió archivo',
           
            statusproject:"OCULTO", 
            formato:"CORTO", 
         
            images:[], //imagenes agregadas
 
            //bloques
            body:[],
            tipo:'texto',
            caja1:'texto',
            caja2:'texto',
            image:null,
            url:'',
            name_imagen:'',
            titulo_centrado:'',
            subtitulo_centrado:'',
            video:'',
            reto:'',
            parrafo:'',
            texto: "",

            image2:null,
            name_imagen2:'',
            titulo_centrado2:'',
            subtitulo_centrado2:'',
            video2:'',
            reto2:'',
            parrafo2:'',
            texto2: "",

            slider:[],
            galeria:[],


            editorOption: { 
                modules: {
                           
                        },
                        
                        theme: 'snow'
                },

           status:'',
           message:'',


           //selects

            active: false,
            active2: false,
            active3: false,
            active4: false,
            active5: false,
            active6: false,
            active7: false,

            viewModal:false,


            search_value:'',
            updsearchcli:'',

        }
    },
    
    created(){
      
         this.getAllInfoPct('project_category')
  
    },
    computed:{
  
       categorias() {
            return this.$store.getters["project_category/data"];
      },
    },
    methods:{
     
         ...mapActions('project_category', ['getAllInfoPct']),
       
        ...mapActions('project', ['setAddedPrj']),
         ...mapActions('project', ['setHistoryOptionPrj']),
         UpItem: function(item, key){
        let bloques =this.body;
      
        let anterior = key-1
        let actual = key
        let item_actual =item
        let item_anterior = bloques[key-1]

        let array = []
        
        for(var i=0; i<bloques.length;i++){
          if(i == actual){
            array.push(item_anterior)
          }else if(i==anterior){
            array.push(item_actual)
          }else{
            array.push(bloques[i])
          }
        }

        this.body =array
    },
    DownItem: function(item, key){
      let bloques =this.body;
      
      let siguiente = key+1
      let actual = key
      let item_actual =item
      let item_siguiente = bloques[key+1]

       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i == actual){
          array.push(item_siguiente)
        }else if(i==siguiente){
          array.push(item_actual)
        }else{
          array.push(bloques[i])
        }
      }

      this.body =array

    },
    DelItem: async function(bloq, key){
      let bloques =this.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }
      }

      this.body =array

      //! eliminar la imagen si es de tipo imagen.
      if(bloq.tipo=='imagen' || blog.tipo =='paleta'){
       
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.imagen});
      }

      if(bloq.tipo=='slider' ){
       
         for (const img of bloq.slider) {
              let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:img});
         }
      } 

      if(bloq.tipo=='galeria' ){
       
         for (const img of bloq.galeria) {
              let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:img});
         }
      } 



      if(bloq.tipo =='caja'){
          if(bloq.caja[0].tipo =='imagen'){
         
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.caja[0].imagen});
         }

          if(bloq.caja[1].tipo =='imagen'){
         
          let result = await this.$store.dispatch("project/deleteItemPrj", {option: 'projectimage', id:bloq.caja[1].imagen});
         }
      }

    },
    CambiarOrdenCaja: function(bloq, key){
        let bloques =this.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }else{
          let model  ={ 'caja': [bloq.caja[1], bloq.caja[0]] , 'tipo': 'caja'}
          array.push(model)
        }
      }

      this.body =array
    },
        search: async function(value){
            let option = 'tag';
           
          
            let payload ={
              option : option,
              search: value
            }
            if(value!=""){
                this.$store.dispatch("tag/searchTg", payload);
            }else{
               this.getAllInfoTg('tag')
            }
         },
  
         
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },
     closeSelect2() {
      this.active2 = false;
      document.getElementById("select-ch2").checked = false;
    },
      closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },
      closeSelect4() {
      this.active4 = false;
      document.getElementById("select-ch4").checked = false;
    },
     closeSelect5() {
      this.active5 = false;
      document.getElementById("select-ch5").checked = false;
    },
     closeSelect6() {
      this.active6 = false;
      document.getElementById("select-ch6").checked = false;
    },
     closeSelect7() {
      this.active7 = false;
      document.getElementById("select-ch7").checked = false;
    },


          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        submit: async function( title, descrp, id_category,  statusproject, formato,  file1, body,  images, url, color, file2,file3,file4){
                 this.status =''
                 this.message =''
                
                //todo validar body almenos 1 bloque.

                if(file1 == null || file2 ==null || file3 ==null || file4 ==null){
                      this.status='error'
                        this.message= 'Imagen de portada, portada min o portada de home no subida.'
                    return null
                }
    
             
            
                body = JSON.stringify(body)
           
               images = JSON.stringify(images)
               


                   var data = new  FormData();
                    data.append('title', title);
                    data.append('descrp', descrp);

                      data.append('url', url);
                    
                    data.append('id_category', id_category);
                 
                    data.append('statusproject', statusproject);
                    data.append('formato', formato);
                    data.append('body', body);
                     data.append('color',color);
                    data.append('image', file1);
                    data.append('imagenes', images);
                    data.append('imagemin', file2);
                        data.append('imagehome', file3);
                        data.append('imagemo', file4);
                    data.append('_method', 'POST');

                  

                   let result = await this.$store.dispatch("project/addItemPrj",  {option:'project', item: data});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedPrj(result.added )
                       this.showModal()
                        
                       
                    } 
                    

            
        },
        
     delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000); 
        },
    closeSelect() {
        this.active = false;
        document.getElementById("select-ch").checked = false;
        },

        showModal: function(){
            this.viewModal=true
        },
        closeModal:function(){
            this.viewModal=false;
            this.setAddedPrj('')
            this.setHistoryOptionPrj('Default')
        },
        Add:function(){
            this.status='';
            this.message='';
            this.title=''; 
            this.url=''; 
            this.descrp=''; 
            
            this.id_category=''; 
            this.catname='';
        
            this.file1=null;
            this.archivo='No se eligió archivo';
this.color='#000000';
              this.file2=null;
            this.archivo2='No se eligió archivo';

             this.file3=null;
            this.archivo3='No se eligió archivo';

             this.file4=null;
            this.archivo4='No se eligió archivo';
        
            this.statusproject=''; 
            this.formato=''; 
          
            this.images=[]; 
            this.slider=[]; 
            this.galeria=[]; 
           
           //!resetear bloques
            this.body=[];
            this.tipo='texto';
            this.caja1='texto';
            this.caja2='texto';
            this.image=null;
            
            this.name_imagen='';
            this.titulo_centrado='';
            this.subtitulo_centrado='';
            this.video='';
            this.reto='';
            this.parrafo='';
            this.texto= "";

            this.image2=null;
            this.name_imagen2='';
            this.titulo_centrado2='';
            this.subtitulo_centrado2='';
            this.video2='';
            this.reto2='';
            this.parrafo2='';
            this.texto2= "";
            
            
            this.viewModal=false;
            this.setAddedPrj('');
        },
        toEdit:function(){
            
            
            this.setHistoryOptionPrj('Edit')
            this.viewModal=false;
        },
        back:function(){
            setTimeout(() => this.$router.go(), 200);
        },

            previewFiles5(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file3= files[0];
            this.archivo3 = this.file3.name
        },


            previewFiles6(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file4= files[0];
            this.archivo4 = this.file4.name
        },

        añadirSlider: async function(){
            

            if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const img of this.slider){
                        array.push(img)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                        
                            array.push(result.fileName)
                            this.slider = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }


        }
        ,

        añadirGaleria: async function(){
            

            if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const img of this.galeria){
                        array.push(img)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                        
                            array.push(result.fileName)
                            this.galeria = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }


        }
        ,

        // bloques
        async guardarBloque(tipo){
            if(tipo=='texto'){
                if(this.texto==undefined || this.texto=='' || this.texto==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'texto': this.texto, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.texto =""
                }


            }else if(tipo=='video'){
                if(this.video==undefined || this.video=='' || this.video==null){
                    this.status='error'
                    this.message='Agrega un video.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'video': this.video, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.video =""
                }


            }else if(tipo=='slider'){
                if(this.slider =='' || this.slider == undefined || this.slider == null || this.slider.length ==0){
                    this.status='error'
                    this.message='El slider aun no contiene imagenes.'
                   
                    this.delStatus()
                }else{
                     let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'slider': this.slider, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.slider =[];
                    this.image=null
                    this.name_imagen =''
                }

            }else if(tipo=='galeria'){
                if(this.galeria =='' || this.galeria == undefined || this.galeria == null || this.galeria.length ==0){
                    this.status='error'
                    this.message='El galeria aun no contiene imagenes.'
                   
                    this.delStatus()
                }else{
                     let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'galeria': this.galeria, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.galeria =[];
                    this.image=null
                    this.name_imagen =''
                }

            }else if(tipo=='imagen'){
               if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                         let model ={'imagen': result.fileName, 'tipo': tipo}
                            array.push(model)
                            this.body = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }
            }else if(tipo=='paleta'){
               if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                    this.image=null
                        this.name_imagen =''
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                   
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array2 =[]
                         for (const img of this.images) {
                            array2.push(img)
                         }
                         array2.push(result.fileName)
                        this.images = array2

                         let model ={'imagen': result.fileName, 'tipo': tipo}
                            array.push(model)
                            this.body = array
                        
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        //enviar a borrar la imagen
                        
                    }
                    
                        this.image=null
                        this.name_imagen =''
                        this.name_imagen =""

                }
            }else if(tipo=='reto'){
               if(this.reto==undefined || this.reto=='' || this.reto==null || this.parrafo==null || this.parrafo==null || this.parrafo==null){
                    this.status='error'
                    this.message='reto o parrafo vacia.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'reto': {'texto':this.reto, 'parrafo':this.parrafo}, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.reto =""
                    this.parrafo =""
                }
            }else if(tipo=='titulo_centrado'){
               if(this.titulo_centrado==undefined || this.titulo_centrado=='' || this.titulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'titulo_centrado': this.titulo_centrado, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.titulo_centrado =""
                    
                }
            }else if(tipo=='subtitulo_centrado'){
               if(this.subtitulo_centrado==undefined || this.subtitulo_centrado=='' || this.subtitulo_centrado==null ){
                    this.status='error'
                    this.message='Subtítulo centrado vacio.'
                    this.delStatus()
                }else{
                    let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                    let model ={'subtitulo_centrado': this.subtitulo_centrado, 'tipo': tipo}
                    array.push(model)
                    this.body = array
                    this.subtitulo_centrado =""
                    
                }
            }else if(tipo=='caja'){
                let item1;
                let item2;
                let statusC1='success'
                let statusC2='success'
                if(this.caja1=='texto'){

                    if(this.texto==undefined || this.texto=='' || this.texto==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC1='error'
                    this.delStatus()
                    }else{
                       

                        item1 ={'texto': this.texto, 'tipo': this.caja1}

                       
                       
                    }
                }else if(this.caja1=='titulo_centrado'){
                    if(this.titulo_centrado==undefined || this.titulo_centrado=='' || this.titulo_centrado==null ){
                    this.status='error'
                    this.message='Titulo centrado vacia.'
                      statusC1='error'
                    this.delStatus()
                        }else{
                        

                            item1 ={'titulo_centrado': this.titulo_centrado, 'tipo': this.caja1}
                        
                            
                        }

                }else if(this.caja1=='subtitulo_centrado'){
                    if(this.subtitulo_centrado==undefined || this.subtitulo_centrado=='' || this.subtitulo_centrado==null ){
                    this.status='error'
                    this.message='Subtítulo centrado vacia.'
                      statusC1='error'
                    this.delStatus()
                        }else{
                        

                            item1 ={'subtitulo_centrado': this.subtitulo_centrado, 'tipo': this.caja1}
                        
                            
                        }

                }else if(this.caja1=='reto'){
                            if(this.reto==undefined || this.reto=='' || this.reto==null || this.parrafo==null || this.parrafo==null || this.parrafo==null){
                            this.status='error'
                            this.message='reto o parrafo vacia.'
                            statusC1='error'
                            this.delStatus()
                        }else{
                        
                         item1 ={'reto': {'texto':this.reto, 'parrafo':this.parrafo}, 'tipo': this.caja1}
                        
                        }
                }else if(this.caja1 =='imagen'){
                          if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC1='error'
                    this.delStatus()
                }else{
                  

                    
                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                     
                
                    data.append('image', this.image);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                   
                  
                   
                    if(result.status=='success'){
                        
                         let array =[]
                         for (const img of this.images) {
                            array.push(img)
                         }
                         array.push(result.fileName)
                        this.images = array
                        item1 ={'imagen':result.fileName, 'tipo': this.caja1}
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                          statusC1='error'
                        //enviar a borrar la imagen
                        
                    }
                    



                }
                }else{
                    statusC1='error'
                }

                if(this.caja2=='texto'){
                    if(this.texto2==undefined || this.texto2=='' || this.texto2==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC2='error'
                    this.delStatus()
                    }else{
                       

                        item2 ={'texto': this.texto2, 'tipo': this.caja2}
                       
                       
                    }
                }else if(this.caja2=='titulo_centrado'){
                    if(this.titulo_centrado2==undefined || this.titulo_centrado2=='' || this.titulo_centrado2==null ){
                        this.status='error'
                        this.message='Titulo centrado vacia.'
                        statusC2='error'
                        this.delStatus()
                        }else{
                        

                            item2 ={'titulo_centrado': this.titulo_centrado2, 'tipo': this.caja2}
                        }
                        
                            
                }else if(this.caja2=='subtitulo_centrado'){
                    if(this.subtitulo_centrado2==undefined || this.subtitulo_centrado2=='' || this.subtitulo_centrado2==null ){
                        this.status='error'
                        this.message='Titulo centrado vacia.'
                        statusC2='error'
                        this.delStatus()
                        }else{
                        

                            item2 ={'subtitulo_centrado': this.subtitulo_centrado2, 'tipo': this.caja2}
                        }
                        
                            
                }else if(this.caja2=='reto'){
                            if(this.reto2==undefined || this.reto2=='' || this.reto2==null || this.parrafo2==null || this.parrafo2==null || this.parrafo2==null){
                            this.status='error'
                            this.message='reto o parrafo vacia.'
                            statusC2='error'
                            this.delStatus()
                        }else{
                        
                         item2 ={'reto': {'texto':this.reto2, 'parrafo':this.parrafo2}, 'tipo': this.caja2}
                        
                        }
                }else if(this.caja2 =='imagen'){
                          if(this.name_imagen2==undefined || this.name_imagen2=='' || this.name_imagen2==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC2='error'
                    this.delStatus()
                }else{
                  

                    
                   

                    //* subir imagen al backend
                   
                     var data = new  FormData();
                     
                
                    data.append('image', this.image2);
                    data.append('_method', 'POST');

                  

                    let result = await this.$store.dispatch("project/addItemPrj",  {option:'project-image', item: data});
                 
                  
                   
                    if(result.status=='success'){
                        
                         let array =[]
                         for (const img of this.images) {
                            array.push(img)
                         }
                         array.push(result.fileName)
                        this.images = array

                        item2 ={'imagen': result.fileName, 'tipo': this.caja2}
                       
                    }else{
                         this.status='error'
                        this.message= result.message
                        statusC2='error'
                        //enviar a borrar la imagen
                        
                    }
                    



                }
                }else{
                    statusC2='error'
                }

                if(statusC1 == 'success' && statusC2 =='success'){
                    let model  ={ 'caja': [item1, item2] , 'tipo': tipo}


                     let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                   
                    array.push(model)
                    this.body = array
                    this.texto=""
                     this.texto2=""
                    this.titulo_centrado =""
                     this.titulo_centrado2 =""
                      this.subtitulo_centrado =""
                     this.subtitulo_centrado2 =""
                      this.reto =""
                     this.reto2 =""
                     this.parrafo =""
                     this.parrafo2 =""
                     this.image=""
                     this.name_imagen=""
                      this.image2=""
                     this.name_imagen2=""
                }else{
                      this.status='error'
                    this.message='Una de las cajas esta vacia.'
                   
                    this.delStatus()
                }


            }else{
                this.status='error'
                this.message='Selecciona un tipo de bloque.'
                this.delStatus()
            }
        },



           
          delStatus: function () {
            setTimeout(() => this.delMsgs(), 2000);
            
            },
            delMsgs: function () {
            this.status = "";
            this.message = "";
            },

        toolbar:function(){
             if(this.tipo == 'video'){
                  return  [
                        [  'video' ], 
                    ];
             }else if(this.tipo =='imagen'){
                  return  [
                        [  'image' ], 
                    ];
             }else{
                 return  [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
               // [ 'link','image' ,'video' ],          // add's image support
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
             }
            },
             previewFiles(e) {
              
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image= files[0];
              
            this.name_imagen = this.image.name
        },

           previewFiles3(e) {
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image2= files[0];
           
            this.name_imagen2 = this.image2.name
        },


        previewFiles2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file1= files[0];
            this.archivo = this.file1.name
        },



        previewFiles4(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file2= files[0];
            this.archivo2 = this.file2.name
        },

           srcImg:function (img){
                let src = `${urlpath.url()}/project-img/${img}`
                
                //this.status_img(img)
                 
                return src
        },
           status_img:async  function (img){
                 let payload ={
                    option:'colaboradores',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                    if(response.data.status=='error'){
                        this.statusimg='error'
                    }else{
                        this.statusimg='success'
                    }
                }catch(err){
                    this.statusimg='error'
                }

               
               
    },
    }
}
</script>
<style >
.mlf{
    margin-left: 1vw;
}

img.imgslider{
    width: 10vw !important;
}
.mtp{
    margin-top: 3vw;
}
.opciones-bloque{
    min-height: 20vw;
}

/* QUILL EDITOR */
.quill-editor , .quill-editor-imagen , .quill-editor-video {
  margin-bottom: 3vw;
  margin-top: 1vw;
  
}
.quill-editor{
  width: 53.65vw;
}
.quill-editor-imagen, .quill-editor-video{
    width: 27.65vw;
}

.tbl1, .tbl2{
    width: 20vw;
   
    margin-right: 1vw;
}

p.et1{
    width: 15vw;
    cursor: default;
}
p.elim{
     cursor: pointer;
     color: var(--color-3) !important;
 
    
}

.bodytbl{
    height: 10vw;
    overflow: auto;
    padding: 1vw;
     background: var(--color-2);
}
p.etiqueta{
    color: var(--color-5);
   
    font-size: 0.6VW;
}

.hdreti{
    background:  var(--color-1);
    padding: .5vw 1vw;
    color:  var(--color-5);
    font-weight: 600;
    font-size: 0.8VW;
}


    
    #AddBlo label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #AddBlo input, #AddBlo select{
        height: 2.5VW;
        width: 13.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw 1.5vw 1.5vw 0vw;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
        padding-right: 1vw;
        margin-right:2vw;
    }


#select-role {
  height: 2.5vw;
  width: 14.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}#select-ch:checked ~ .options-cont {
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 12.3vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}#select-ch:checked ~ .options-cont , #select-ch2:checked ~ .options-cont, #select-ch3:checked ~ .options-cont , #select-ch4:checked ~ .options-cont , #select-ch5:checked ~ .options-cont  , #select-ch6:checked ~ .options-cont , #select-ch7:checked ~ .options-cont{
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 13.32vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}
   
.buscador input {
    width: 15.312500000000002VW;
    border: none !important;
    border-radius: none !important;
    height: 1.5625VW !important;
    color: #8D8D8D;
}
.mtbtn{
        margin-left: 1vw;
    margin-top: 1.4vw;
}

.optionsbl{
    margin-top: 1vw;
}

.col1{
 
     
    width: 5vw;
    text-align: center;
   
}
.col2{

     
      width: 5vw;
    text-align: center;
}
.col3{
   
     overflow: auto;
    width: 56vw;
    
}
.cl3t{
    text-align: center;
}

.col3 p{
    padding-left: 1vw;
}
.col4{
   
    
      width: 5vw;
    text-align: center;
}


.header-bc p{
    margin-right: 1px ;
     color: aliceblue;
    background: var(--color-2);
    margin-bottom: 0vw;
    font-weight: 600;
    padding: 0.5vw 0vw;
    
}

.bloq-item{
  border-bottom: .2vw solid var(--color-4);
   color: var(--color-4);
  
}

.bloq-item h2:hover{
    color: var(--color-1);
}

.bloques-cont{
    width: 71vw;
    margin-bottom: 5vw;
}

h2.cp{
    cursor: pointer;
}
.caja1, .caja2{
    width: 50%;
}

.col3 img{
        width: 72%;
    padding: 0.5vw 3.5vw;
}

.col3 iframe{
    padding: 0.5vw 3.5vw;
}
 
p.btnStatus{
     cursor: pointer;
    border: none;
    background: var(--color-4);
    color: var(--color-5);
    width: 6VW;
    text-align: center;
    padding: .5vw 0vw;
    margin-right: 1vw;
    border-radius: 1.5vw;
    margin-top: 0.7291666666666666VW;
    font-weight: 600;
    font-size: 0.7291666666666666VW;
}

.statusVisible{
     background: var(--color-1) !important;
}
</style>



