<template>
    <div id="SideMenu" class="dflx">
        <div class="box-1">
            <div class="logo">
                <img src="../assets/logo.png" alt="logo aplicación">
            </div>
            <div class="msg-user">
                <p>¡HOLA, <span>{{ identity.name | splitUp }}!</span></p>
            </div>
            <div class="menu">
                <ul>
                    <li :class="{ active: navOption == 'Dashboard' }" @click="activeMenu(0), miPagina()">
                        <div class="text-menu">MI <span> PÁGINA</span></div>
                        <div v-if="navOption == 'Mi Página'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>
                  <!--
                       <li :class="{ active: navOption == 'Perzonalizacion' }"
                        @click="activeMenu(0), setOption('Perzonalizacion')">
                        <div class="text-menu"> <span>PERSONALIZACIÓN</span> </div>
                        <div v-if="navOption == 'Perzonalizacion'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>
                  -->
                    <li :class="{ active: navOption == 'Usuarios' }"
                        @click="activeMenu(0), setOption('Usuarios'), setHistoryOptionUsr('Default')">
                        <div class="text-menu"><span>USUARIOS</span></div>
                        <div v-if="navOption == 'Usuarios'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>

                    <li :class="{ active: navOption == 'MenuB' }" @click="activeMenu(1), setOption('MenuB')">
                        <div class="text-menu"> MENÚ <span> BLOG</span></div>
                        <div v-if="navOption == 'MenuB' || hidemenu" class="icon-active-menu">
                            <div class="ico-2"></div>
                        </div>
                    </li>
                    <li class="submenu" :class="{ active: navOption == 'BLOG' }" v-if="hidemenu"
                        @click="setOption('BLOG'), setHistoryOptionBlo('Default')"> &#10625; BLOG</li>
                    <li class="submenu" :class="{ active: navOption == 'BLOGCAT' }" v-if="hidemenu"
                        @click="setOption('BLOGCAT'), setHistoryOptionBct('Default')"> &#10625; CATEGORÍA </li>

                    <li :class="{ active: navOption == 'MenuP' }" @click="activeMenu(2), setOption('MenuP')">
                        <div class="text-menu"> MENÚ <span> PORTAFOLIO</span></div>
                        <div v-if="navOption == 'MenuP' || hidemenu2" class="icon-active-menu">
                            <div class="ico-2"></div>
                        </div>
                    </li>
                    <li class="submenu" :class="{ active: navOption == 'PROJ' }" v-if="hidemenu2"
                        @click="setOption('PROJ'), setHistoryOptionPrj('Default')"> &#10625; PORTAFOLIO </li>
                    <li class="submenu" :class="{ active: navOption == 'PROJCAT' }" v-if="hidemenu2"
                        @click="setOption('PROJCAT'), setHistoryOptionPct('Default')"> &#10625; CATEGORÍA </li>

                    <li :class="{ active: navOption == 'colaboradores' }"
                        @click="activeMenu(0), setOption('colaboradores'), setHistoryOptionClb('Default')">
                        <div class="text-menu"><span>COLABORADORES</span></div>
                        <div v-if="navOption == 'colaboradores'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>
                    <li :class="{ active: navOption == 'historias' }"
                        @click="activeMenu(0), setOption('historias'), setHistoryOptionHts('Default')">
                        <div class="text-menu"><span>HISTORIAS</span> DE <span>ÉXITO</span></div>
                        <div v-if="navOption == 'historias'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>

                    <li :class="{ active: navOption == 'TAG' }"
                        @click="activeMenu(0), setOption('TAG'), setHistoryOptionTg('Default')">
                        <div class="text-menu"><span>ETIQUETAS</span></div>
                        <div v-if="navOption == 'TAG'" class="icon-active">
                            <div class="ico"></div>
                        </div>
                    </li>

                    <li :class="{ active: navOption == 'MenuV' }" @click="activeMenu(3), setOption('MenuV')">
                        <div class="text-menu"><span>VIDEOS</span></div>
                        <div v-if="navOption == 'MenuV' || hidemenu3" class="icon-active">
                            <div class="ico-2"></div>
                        </div>
                    </li>
                    <li class="submenu" :class="{ active: navOption == 'videos' }" v-if="hidemenu3"
                        @click="setOption('videos'), setHistoryOptionVdo('Default')"> &#10625; VIDEOS </li>
                    <li class="submenu" :class="{ active: navOption == 'VDOCAT' }" v-if="hidemenu3"
                        @click="setOption('VDOCAT'), setHistoryOptionVct('Default')"> &#10625; CATEGORÍA </li>




                    <li :class="{ active: navOption == 'MenuS' }" @click="activeMenu(4), setOption('MenuS')">
                        <div class="text-menu"><span>SERVIDORES</span></div>
                        <div v-if="navOption == 'MenuS' || hidemenu4" class="icon-active">
                            <div class="ico-2"></div>
                        </div>
                    </li>
                    <li class="submenu" :class="{ active: navOption == 'paquetes' }" v-if="hidemenu4"
                        @click="setOption('paquetes'), setHistoryOptionSrv('Default')"> &#10625; PAQUETES </li>
                    <li class="submenu" :class="{ active: navOption == 'clientes' }" v-if="hidemenu4"
                        @click="setOption('clientes'), setHistoryOptionCli('Default')"> &#10625; CLIENTES </li>


                </ul>
            </div>
        </div>
        <div class="box-2">
            <div class="triangulo"> </div>
        </div>
    </div>
</template>
<script>

import { mapActions } from 'vuex';
export default {
    name: 'SideMenu',
    data() {
        return {
            navOption: 'Default',
            hidemenu: false,
            hidemenu2: false,
            hidemenu3: false,
            hidemenu4: false,
        }
    },
    created() {
        this.getIdentity()
        let nav = localStorage.getItem('nav')

        if (nav) {
            this.setHistoryOptionMain(nav)
            this.navOption = nav

            if (nav == 'BLOG' || nav == 'BLOGCAT') {
                this.hidemenu = true
            }

            if (nav == 'PROJ' || nav == 'PROJCAT') {
                this.hidemenu2 = true
            }

            if (nav == 'videos' || nav == 'VDOCAT') {
                this.hidemenu3 = true
            }
            if (nav == 'paquetes' || nav == 'clientes') {
                this.hidemenu4 = true
            }

        }
    },
    computed: {
        identity() {
            return this.$store.getters['admin/getIdentity'];
        },
    },
    methods: {
        ...mapActions('main', ['setHistoryOptionMain']),
        ...mapActions('blog', ['setHistoryOptionBlo']),
        ...mapActions('blog_category', ['setHistoryOptionBct']),
        ...mapActions('usuarios', ['setHistoryOptionUsr']),
        ...mapActions('tag', ['setHistoryOptionTg']),

        ...mapActions('blog_category', ['getAllInfoBct']),
        ...mapActions('blog', ['getAllInfoBlo']),
        ...mapActions('tag', ['getAllInfoTg']),
        ...mapActions('usuarios', ['getAllInfoUsr']),


        ...mapActions('colaboradores', ['setHistoryOptionClb']),
        ...mapActions('colaboradores', ['getAllInfoClb']),

        ...mapActions('historias', ['setHistoryOptionHts']),
        ...mapActions('historias', ['getAllInfoHts']),

        ...mapActions('project_category', ['setHistoryOptionPct']),
        ...mapActions('project_category', ['getAllInfoPct']),

        ...mapActions('videos', ['setHistoryOptionVdo']),
        ...mapActions('videos', ['getAllInfoVdo']),

        ...mapActions('videos_category', ['setHistoryOptionVct']),
        ...mapActions('videos_category', ['getAllInfoVct']),

        
        ...mapActions('project', ['setHistoryOptionPrj']),
        ...mapActions('project', ['getAllInfoPrj']),
        
        ...mapActions('servidores', ['setHistoryOptionSrv']),
        ...mapActions('servidores', ['getAllInfoSrv']),
        
        ...mapActions('clients', ['setHistoryOptionCli']),
        ...mapActions('clients', ['getAllInfoCli']),
        ...mapActions('paquete', ['getAllInfoPqt']),
        activeMenu(value) {
            if (value == 1) {
                this.hidemenu ? this.hidemenu = false : this.hidemenu = true;
            } else if (value == 2) {
                this.hidemenu2 ? this.hidemenu2 = false : this.hidemenu2 = true;
            } else if (value == 3) {
                this.hidemenu3 ? this.hidemenu3 = false : this.hidemenu3 = true;
            } else if (value == 4) {
                this.hidemenu4 ? this.hidemenu4 = false : this.hidemenu4 = true;
            } else {
                this.hidemenu = false
                this.hidemenu2 = false
                this.hidemenu3 = false
                this.hidemenu4 = false
            }

        },
        getIdentity: async function () {
            let response = await this.$store.dispatch("admin/getData");
            return response
        },



        setOption: function (option) {
            if (option == 'MenuB' || option == 'MenuP' || option == 'MenuV' || option == 'MenuS') {
                this.navOption = option
            } else {
                this.navOption = option
                localStorage.setItem('nav', option)
                this.setHistoryOptionMain(option)


                //getAll info
                if (option == 'Usuarios') {
                    this.getAllInfoUsr('usuarios')
                } else if (option == 'BLOG') {
                    this.getAllInfoBlo('blog')
                } else if (option == 'BLOGCAT') {
                    this.getAllInfoBct('blog_category')
                } else if (option == 'PROJCAT') {
                    this.getAllInfoPct('project_category')
                } else if (option == 'PROJ') {
                    this.getAllInfoPrj('project')
                } else if (option == 'TAG') {
                    this.getAllInfoTg('tag')
                } else if (option == 'colaboradores') {
                    this.getAllInfoClb('colaboradores')
                } else if (option == 'videos') {
                    this.getAllInfoVdo('videos')

                } else if (option == 'VDOCAT') {
                    this.getAllInfoVct('videos_category')
                } else if (option == 'historias') {
                    this.getAllInfoHts('historias')
                } else if (option == 'clients') {
                    this.getAllInfoCli('clients')
                } else if (option == 'servidores') {
                    this.getAllInfoSrv('servidores')
                } else if (option == 'paquetes') {
                    this.getAllInfoPqt('paquete');
                } 
            }

           

        },
        wait: function () {
            setTimeout(() => this.$router.go(), 0);
        },

        miPagina() {

            window.open('https://legrafica.mx/', '_blank');
        }
    },
    filters: {

        splitUp: (value) => {
            if (!value) return '';
            value = value.toString();

            let splitStr = value.toUpperCase().split(' ');



            return splitStr[0].charAt(0) + splitStr[0].substring(1);

        },


    }


}
</script>
<style scoped>
#SideMenu {
    min-height: 59.25VW;
}

.box-1 {
    width: 16.614583333333332VW;
    background: var(--color-2);
}

.logo {
    margin-top: 4.166666666666666VW;
    margin-left: 2.03125VW;
}

.logo img {
    width: 12.604166666666666VW;
    height: 4.583333333333333VW;
}

.msg-user {
    margin-left: 2.03125VW;
    margin-top: 0.78125VW;
    width: 12.552083333333334VW;
    height: 2.03125VW;
    background: var(--color-5);
    border-radius: 1vw;
}

.msg-user p {
    color: var(--color-1);
    text-align: center;
    height: 2.03125VW;
    padding-top: .5vw;
    font-weight: 500;
    margin: 0px;
    font-size: 0.625VW;
}

.msg-user p span {
    font-weight: 800;
}

.box-2 {
    width: 4.21875VW;
}

.triangulo {
    width: 0;
    height: 0;
    border-right: 4.21875VW solid transparent;
    border-top: 4.21875VW solid var(--color-2);
}

.menu {
    margin-top: 5.677083333333333VW;
}


li.submenu {
    border: none !important;
}

.menu ul {
    margin-left: 2.03125VW;
    padding: 0vw;
}

.menu ul li {

    height: 1.6VW;
    width: 11.352083VW;
    border: 1px solid var(--color-5);
    color: var(--color-5);
    border-radius: 1.2vw;
    padding-left: 1vw;
    padding-top: .5vw;
    padding-bottom: .2vw;
    margin-bottom: 0.6020833333333334VW;
    cursor: pointer;
    display: flex;
    font-size: 0.7291666666666666VW;
}

.menu ul li span {
    font-weight: 700;

}

.text-menu {
    width: 9.5vw;
    padding-top: .1vw;
}

.active {
    background: var(--color-6);

}

li.active {
    border: 0px !important
}

.icon-active,
.icon-active-menu {
    width: 1.3031249999999999VW;
    height: 1.3031249999999999VW;
    background: var(--color-5);
    border-radius: 1vw;

}

.ico {
    width: 0.6515624999999999VW;
    height: 0.6515624999999999VW;
    background: var(--color-2);
    border-radius: 1vw;
    margin-top: .33vw;
    margin-left: .34vw;

}

.ico-2 {
    margin-top: .55vw;
    margin-left: .3vw;
    width: 0;
    height: 0;
    border-right: 0.364583vw solid transparent;
    border-top: 0.364583vw solid var(--color-2);
    border-left: 0.364583vw solid transparent;
    border-bottom: 0.364583vw solid transparent;
}
</style>
