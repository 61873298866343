import { render, staticRenderFns } from "./editBct.vue?vue&type=template&id=afa74278&scoped=true"
import script from "./editBct.vue?vue&type=script&lang=js"
export * from "./editBct.vue?vue&type=script&lang=js"
import style0 from "./editBct.vue?vue&type=style&index=0&id=afa74278&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afa74278",
  null
  
)

export default component.exports