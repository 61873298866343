<template >
    <div id="AddHts">
       
          <div class="form-content">
            <form @submit.prevent="
                submit(
                    body,
                    images
                )
              ">
    

            
     

                   
                   <!-- Añadir bloques -->
                 
      
        <p class="subtitle2">Cuerpo</p>

      <div class="opciones-bloque">
            
            <div class="dflx optionsbl">
                

                       


                <div  @click="guardarBloque(tipo)"  class="btn_añadir dflx mtbtn">
                                <p > agregar bloque</p>  <img src="../../assets/add.png" alt="">
               </div>
            </div>   

                  

                
                <div v-if="tipo == 'caja'">
                     <p class="subtitle">Caja 1</p>
                    
                        <div class="dflx btnsgrp">
                          
                            <p class="btnopciones" @click="caja1 = 'info'">Información</p>
                            <p class="btnopciones" @click="caja1 = 'imagen'">Imagen</p>
                            
                        </div>

                       

                 <div v-if="caja1=='info'">
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo" class="titulo">Título</label>
                            <input v-model="titulo" type="text" id="titulo" name="titulo">
                        </div>
                    </div>

                    <vue-editor 
                        id="editor"
                        class="quill-editor"
                        :editorToolbar="toolbar()"
                        v-model="texto"
                        >
                    </vue-editor> 

                      <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="facebook" class="facebook">Facebook</label>
                            <input v-model="facebook" type="text" id="facebook" name="facebook">
                        </div>

                        <div class="boxform">
                          <label for="instagram" class="instagram">Instagram</label>
                            <input v-model="instagram" type="text" id="instagram" name="instagram">
                        </div>

                        
                    </div>

                     <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="twitter" class="twitter">Twitter</label>
                            <input v-model="twitter" type="text" id="twitter" name="twitter">
                        </div>

                        <div class="boxform">
                          <label for="sitioweb" class="sitioweb">Sitio Web</label>
                            <input v-model="sitioweb" type="text" id="sitioweb" name="sitioweb">
                        </div>

                        
                    </div>


                </div>
                   
                    <div class="" v-if="caja1 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg2"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg2">   {{name_imagen}}</label>
                        </div> 
                            
                            <input @change="previewFiles" type="file" id="uploadimg2" name="uploadimg2"    required class="fileinput2">
                    </div>

                    <p class="subtitle">Caja 2</p>

                         <div class="dflx btnsgrp">
                          
                            <p class="btnopciones" @click="caja2 = 'info'">Información</p>
                            <p class="btnopciones" @click="caja2 = 'imagen'">Imagen</p>
                          
                        </div>

                        
               <div v-if="caja2=='info'">
                      
                       <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="titulo2" class="titulo2">Título</label>
                            <input v-model="titulo2" type="text" id="titulo2" name="titulo2">
                        </div>

                      

                        
                    </div>

                     

                        <vue-editor 
                            id="editor"
                            class="quill-editor"
                            :editorToolbar="toolbar()"
                            v-model="texto2"
                            >
                        </vue-editor> 


                        <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="facebook2" class="facebook2">Facebook</label>
                            <input v-model="facebook2" type="text" id="facebook2" name="facebook2">
                        </div>

                        <div class="boxform">
                          <label for="instagram2" class="instagram2">Instagram</label>
                            <input v-model="instagram2" type="text" id="instagram2" name="instagram2">
                        </div>

                        
                    </div>

                     <div class="grup-form dflx">
                
                        <div class="boxform">
                          <label for="twitter2" class="twitter2">Twitter</label>
                            <input v-model="twitter2" type="text" id="twitter2" name="twitter2">
                        </div>

                        <div class="boxform">
                          <label for="sitioweb2" class="sitioweb2">Sitio Web</label>
                            <input v-model="sitioweb2" type="text" id="sitioweb2" name="sitioweb2">
                        </div>

                        
                    </div>
                  </div>
                  
                     <div class="" v-if="caja2 =='imagen'">
                         
                          <div class="dflx">
                               <label class="filelabel" for="uploadimg3"> <span>Seleccionar Archivo</span></label>  <label class="filelabelname tbl_item" for="uploadimg3">   {{name_imagen2}}</label>
                        </div> 
                            
                            <input @change="previewFiles3" type="file" id="uploadimg3" name="uploadimg3"    required class="fileinput">
                    </div>



                </div>


               
              

      
      </div>
                   
        <hr class="hrhd">
        <p class="subtitle2">Bloques agregados</p>
        
        <div class="bloques-cont">
                   <div class="dflx header-bc">
                    <div class="col1">
                        <p>ordenar</p>
                    
                    </div>
                    <div class="col2">
                            <p> Tipo</p>
                            
                    </div>
                    <div class="col3 cl3t">
                            <p> Contenido</p>
                            
                    </div>
                    <div class="col4">
                           <p>Eliminar</p>
                    </div>
                   </div>
                <div v-for="(bloq, key) in body" :key="key" class="bloq-item dflx">
                    <div class="col1">
                        
                        <h2 v-if="key !=0" @click="UpItem(bloq, key)" class="cp">	&#9650;</h2>
                        <h2 v-if="key < (body.length-1)" @click="DownItem(bloq,key)" class="cp">	&#9660;</h2>
                    </div>
                    <div class="col2">
                           <p >{{bloq.tipo}}</p>
                    </div>
                    <div class="col3"> <!-- contenido del bloque  -->
                       
                        <div v-if="bloq.tipo =='caja'" class="dflx">
                           
                            <div class="caja1">
                                <div v-if="bloq.caja[0].tipo=='info'">
                                            <p > <strong>{{bloq.caja[0].titulo}}</strong> </p>
                                            <p  v-html="bloq.caja[0].texto">  </p>
                                            <div class="dflx"> 
                                                <p>Facebook: {{bloq.caja[0].facebook}}</p> 
                                                <p>Instagram:{{bloq.caja[0].instagram}}</p> 
                                            </div>
                                            <div class="dflx">
                                                <p>Twitter: {{bloq.caja[0].twitter}}</p> 
                                                <p>Sitio web:{{bloq.caja[0].sitioweb}}</p> 
                                            </div>
                                </div>
                             
                                  <img v-if="bloq.caja[0].tipo =='imagen'" :src="srcImg(bloq.caja[0].imagen)" alt="imagen bloque">
                                 
                            </div>
                             <h2 class="cp" @click="CambiarOrdenCaja(bloq, key)">	&#11020;</h2>
                            <div class="caja2">
                                <div v-if="bloq.caja[1].tipo=='info'">
                                        <p > <strong>{{bloq.caja[1].titulo}}</strong> </p>
                                            <p  v-html="bloq.caja[1].texto">  </p>
                                            <div class="dflx"> 
                                                <p>Facebook: {{bloq.caja[1].facebook}}</p> 
                                                <p>Instagram:{{bloq.caja[1].instagram}}</p> 
                                            </div>
                                            <div class="dflx">
                                                <p>Twitter: {{bloq.caja[1].twitter}}</p> 
                                                <p>Sitio web:{{bloq.caja[1].sitioweb}}</p> 
                                            </div>
                                </div>
                              
                                  <img v-if="bloq.caja[1].tipo =='imagen'" :src="srcImg(bloq.caja[1].imagen)" alt="imagen bloque">
                                   
                            </div>
                        </div>
                            
                    </div>
                    <div class="col4">
                           <h2 class="cp"  @click="DelItem(bloq,key)">&#10006;</h2>
                    </div>
                </div>
                <p class="snu" v-if="body.length ==0">Sin contenido agregado al cuerpo del blog</p>
        </div>



                    
                        <div class="dflx">
                                  <p @click="wait()" class="btnRegresar">Regresar</p>
                                  <button class="alta" type="submit">Guardar </button>
                        </div>
                     

                     
            </form>
     


        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_add">
                <div class="body dflx">
                     
                           <h3>Historias de éxito agregada</h3>
                           <img src="../../assets/add.png" alt="icono alerta">
                </div>
           
            <div class="modal_add_btns dflx">
                <p @click="Add()" class="otro">Aceptar</p> <p  @click="toEdit()" class="editar">Editar</p>  <p  @click="back()" class="inicio">Inicio</p>
            </div>
          </div>
        </div> 

        </div>



         <div v-if="status !=''" class="status_messages">
                            <div v-if="status =='success'" class="msg msg_success">
                                 <p>{{message}}</p>
                            </div>
                            <div  v-if="status =='error'" class="msg msg_error">
                                <p>{{message}}</p>
                            </div>
           </div>


    </div>
</template>
<script>


import { VueEditor } from "vue2-editor";
import {mapActions} from 'vuex';
let urlpath = require('../../global/url')

export default {
 components: {
  
    VueEditor,
    
  },
    data(){
        return{
             urlpath: urlpath,
       
            images:[], //imagenes agregadas
 
            //bloques
            body:[],
            tipo:'caja',
            caja1:'info',
            caja2:'info',


            image:null,
            name_imagen:'',
            titulo:"",
            facebook:"",
            instagram:"",
            twitter:"",
            sitioweb:"",
            texto: "",

            image2:null,
            name_imagen2:'',
            titulo2:"",
            facebook2:"",
            instagram2:"",
            twitter2:"",
            sitioweb2:"",
            texto2: "",


            editorOption: { 
                modules: {
                           
                        },
                        
                        theme: 'snow'
                },

           status:'',
           message:'',


           //selects

            active: false,
            active2: false,
            active3: false,
            active4: false,
            active5: false,
            active6: false,
            active7: false,

            viewModal:false,


            

        }
    },

    methods:{
       
        ...mapActions('historias', ['setAddedHts']),
         ...mapActions('historias', ['setHistoryOptionHts']),
         UpItem: function(item, key){
        let bloques =this.body;
      
        let anterior = key-1
        let actual = key
        let item_actual =item
        let item_anterior = bloques[key-1]

        let array = []
        
        for(var i=0; i<bloques.length;i++){
          if(i == actual){
            array.push(item_anterior)
          }else if(i==anterior){
            array.push(item_actual)
          }else{
            array.push(bloques[i])
          }
        }

        this.body =array
    },
    DownItem: function(item, key){
      let bloques =this.body;
      
      let siguiente = key+1
      let actual = key
      let item_actual =item
      let item_siguiente = bloques[key+1]

       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i == actual){
          array.push(item_siguiente)
        }else if(i==siguiente){
          array.push(item_actual)
        }else{
          array.push(bloques[i])
        }
      }

      this.body =array

    },
    DelItem: async function(bloq, key){
      let bloques =this.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }
      }

      this.body =array

      //! eliminar la imagen si es de tipo imagen.
      if(bloq.tipo=='imagen'){
       
          let result = await this.$store.dispatch("historias/deleteItemHts", {option: 'historiasimage', id:bloq.imagen});
      }
      if(bloq.tipo =='caja'){
          if(bloq.caja[0].tipo =='imagen'){
         
          let result = await this.$store.dispatch("historias/deleteItemHts", {option: 'historiasimage', id:bloq.caja[0].imagen});
         }

          if(bloq.caja[1].tipo =='imagen'){
         
          let result = await this.$store.dispatch("historias/deleteItemHts", {option: 'historiasimage', id:bloq.caja[1].imagen});
         }
      }

    },
    CambiarOrdenCaja: function(bloq, key){
        let bloques =this.body;
      

      
       let array = []
      
      for(var i=0; i<bloques.length;i++){
        if(i != key){
          array.push(bloques[i])
        }else{
          let model  ={ 'caja': [bloq.caja[1], bloq.caja[0]] , 'tipo': 'caja'}
          array.push(model)
        }
      }

      this.body =array
    },
       

         
    closeSelect() {
      this.active = false;
      document.getElementById("select-ch").checked = false;
    },
     closeSelect2() {
      this.active2 = false;
      document.getElementById("select-ch2").checked = false;
    },
      closeSelect3() {
      this.active3 = false;
      document.getElementById("select-ch3").checked = false;
    },
      closeSelect4() {
      this.active4 = false;
      document.getElementById("select-ch4").checked = false;
    },
     closeSelect5() {
      this.active5 = false;
      document.getElementById("select-ch5").checked = false;
    },
     closeSelect6() {
      this.active6 = false;
      document.getElementById("select-ch6").checked = false;
    },
     closeSelect7() {
      this.active7 = false;
      document.getElementById("select-ch7").checked = false;
    },


          wait: function(){
            setTimeout(() => this.$router.go(), 200);
        },
        submit: async function( body,  images ){
                 this.status =''
                 this.message =''
                
                //todo validar body almenos 1 bloque.
    
             
            
                body = JSON.stringify(body)
                images = JSON.stringify(images)

                let data = {
                    imagenes: images,
                    body: body
                }
               
                   let result = await this.$store.dispatch("historias/addItemHts",  {option:'historias', item: data});
                   
                    if(result.status=='error'){
                        this.status='error'
                        this.message= result.message
                    }else{ // success
                         this.setAddedHts(result.added )
                       this.showModal()
                        
                       
                    } 
                    

            
        },
        
     delStatus: function () {
            setTimeout(() => this.delMsgs()
            ,2000); 
        },
    closeSelect() {
        this.active = false;
        document.getElementById("select-ch").checked = false;
        },

        showModal: function(){
            this.viewModal=true
        },
        closeModal:function(){
            this.viewModal=false;
            this.setAddedHts('')
            this.setHistoryOptionHts('Default')
        },
        Add:function(){
            this.status='';
            this.message='';
        
            this.images=[]; 
           
           //!resetear bloques
            this.body=[];
            this.tipo='caja';
            this.caja1='info';
            this.caja2='info';
            this.image=null;
            this.name_imagen='';
            this.titulo='';
            this.facebook='';
            this.instagram='';
            this.twitter='';
            this.sitioweb='';
            this.texto= "";

            this.image2=null;
            this.name_imagen2='';
            this.titulo2='';
            this.facebook2='';
            this.instagram2='';
            this.twitter2='';
            this.sitioweb2='';
            this.texto2= "";
            
            
            this.viewModal=false;
            this.setAddedHts('');
        },
        toEdit:function(){
            
            
            this.setHistoryOptionHts('Edit')
            this.viewModal=false;
        },
        back:function(){
            setTimeout(() => this.$router.go(), 200);
        },


        // bloques
        async guardarBloque(tipo){
           if(tipo=='caja'){
                let item1;
                let item2;
                let statusC1='success'
                let statusC2='success'
                if(this.caja1=='info'){

                    if(this.texto==undefined || this.texto=='' || this.texto==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC1='error'
                    this.delStatus()
                    }else if(this.titulo==undefined || this.titulo=='' || this.titulo==null){
                          this.status='error'
                        this.message='Título vacio.'
                        statusC1='error'
                        this.delStatus()
                    }else{
                       

                        item1 ={'texto': this.texto, 'titulo':this.titulo, 'facebook':this.facebook, 
                        'instagram':this.instagram, 'twitter':this.twitter, 'sitioweb':this.sitioweb, 'tipo': this.caja1}

                       
                       
                    }
                }else if(this.caja1 =='imagen'){
                          if(this.name_imagen==undefined || this.name_imagen=='' || this.name_imagen==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC1='error'
                    this.delStatus()
                        }else{
                        

                            
                        

                            //* subir imagen al backend
                        
                            var data = new  FormData();
                            
                        
                            data.append('image', this.image);
                            data.append('_method', 'POST');

                        

                            let result = await this.$store.dispatch("historias/addItemHts",  {option:'historias-image', item: data});
                        
                        
                        
                            if(result.status=='success'){
                                
                                let array =[]
                                for (const img of this.images) {
                                    array.push(img)
                                }
                                array.push(result.fileName)
                                this.images = array
                                item1 ={'imagen':result.fileName, 'tipo': this.caja1}
                            
                            }else{
                                this.status='error'
                                this.message= result.message
                                statusC1='error'
                                //enviar a borrar la imagen
                                
                            }
                            



                        }
                }else{
                    statusC1='error'
                }

                if(this.caja2=='info'){
                    if(this.texto2==undefined || this.texto2=='' || this.texto2==null){
                    this.status='error'
                    this.message='Texto vacio.'
                    statusC2='error'
                    this.delStatus()
                    }else if(this.titulo2==undefined || this.titulo2=='' || this.titulo2==null){
                          this.status='error'
                        this.message='Título vacio.'
                        statusC1='error'
                        this.delStatus()
                    }else{
                       

                     

                          item2 ={'texto': this.texto2, 'titulo':this.titulo2, 'facebook':this.facebook2, 
                        'instagram':this.instagram2, 'twitter':this.twitter2, 'sitioweb':this.sitioweb2, 'tipo': this.caja2}
                       
                       
                    }
                }else if(this.caja2 =='imagen'){
                          if(this.name_imagen2==undefined || this.name_imagen2=='' || this.name_imagen2==null){
                    this.status='error'
                    this.message='No has subido una imagen.'
                     statusC2='error'
                    this.delStatus()
                    }else{
                    

                        
                    

                        //* subir imagen al backend
                    
                        var data = new  FormData();
                        
                    
                        data.append('image', this.image2);
                        data.append('_method', 'POST');

                    

                        let result = await this.$store.dispatch("historias/addItemHts",  {option:'historias-image', item: data});
                    
                    
                    
                        if(result.status=='success'){
                            
                            let array =[]
                            for (const img of this.images) {
                                array.push(img)
                            }
                            array.push(result.fileName)
                            this.images = array

                            item2 ={'imagen': result.fileName, 'tipo': this.caja2}
                        
                        }else{
                            this.status='error'
                            this.message= result.message
                            statusC2='error'
                            //enviar a borrar la imagen
                            
                        }
                        



                    }
                }else{
                    statusC2='error'
                }

                if(statusC1 == 'success' && statusC2 =='success'){
                    let model  ={ 'caja': [item1, item2] , 'tipo': tipo}


                     let array =[];
                    for(const bloque of this.body){
                        array.push(bloque)
                    }

                   
                    array.push(model)
                    this.body = array
                    this.texto=""
                     this.texto2=""

                       this.titulo2='';
                        this.facebook2='';
                        this.instagram2='';
                        this.twitter2='';
                        this.sitioweb2='';

                        this.titulo='';
                        this.facebook='';
                        this.instagram='';
                        this.twitter='';
                        this.sitioweb='';
                 
                     this.image=""
                     this.name_imagen=""
                      this.image2=""
                     this.name_imagen2=""
                }else{
                      this.status='error'
                    this.message='Una de las cajas esta vacia.'
                   
                    this.delStatus()
                }


            }else{
                this.status='error'
                this.message='Selecciona un tipo de bloque.'
                this.delStatus()
            }
        },



           
          delStatus: function () {
            setTimeout(() => this.delMsgs(), 2000);
            
            },
            delMsgs: function () {
            this.status = "";
            this.message = "";
            },

        toolbar:function(){
             if(this.tipo == 'video'){
                  return  [
                        [  'video' ], 
                    ];
             }else if(this.tipo =='imagen'){
                  return  [
                        [  'image' ], 
                    ];
             }else{
                 return  [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
               // [ 'link','image' ,'video' ],          // add's image support
                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': [] }],
                [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ];
             }
            },
             previewFiles(e) {
              
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image= files[0];
              
            this.name_imagen = this.image.name
        },

           previewFiles3(e) {
     
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.image2= files[0];
           
            this.name_imagen2 = this.image2.name
        },


        previewFiles2(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.file1= files[0];
            this.archivo = this.file1.name
        },

           srcImg:function (img){
                let src = `${urlpath.url()}/historias-img/${img}`
                
                //this.status_img(img)
                 
                return src
        },
           status_img:async  function (img){
                 let payload ={
                    option:'colaboradores',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                    if(response.data.status=='error'){
                        this.statusimg='error'
                    }else{
                        this.statusimg='success'
                    }
                }catch(err){
                    this.statusimg='error'
                }

               
               
    },
    }
}
</script>
<style >
.opciones-bloque{
    min-height: 20vw;
}

/* QUILL EDITOR */
.quill-editor , .quill-editor-imagen , .quill-editor-video {
  margin-bottom: 3vw;
  margin-top: 1vw;
  
}
.quill-editor{
  width: 53.65vw;
}
.quill-editor-imagen, .quill-editor-video{
    width: 27.65vw;
}

.tbl1, .tbl2{
    width: 20vw;
   
    margin-right: 1vw;
}

p.et1{
    width: 15vw;
    cursor: default;
}
p.elim{
     cursor: pointer;
     color: var(--color-3) !important;
 
    
}

.bodytbl{
    height: 10vw;
    overflow: auto;
    padding: 1vw;
     background: var(--color-2);
}
p.etiqueta{
    color: var(--color-5);
   
    font-size: 0.6VW;
}

.hdreti{
    background:  var(--color-1);
    padding: .5vw 1vw;
    color:  var(--color-5);
    font-weight: 600;
    font-size: 0.8VW;
}


    
    #AddHts label{
        color: var(--color-2);
        font-weight: 700;
        font-size: 0.7291666666666666VW;
        margin-bottom: .3vw;
    }

    #AddHts input, #AddHts select{
        height: 2.5VW;
        width: 13.716666666666668VW;
        border: 0.052083vw solid var(--color-2);
        border-radius: 0vw 1.5vw 1.5vw 0vw;
        color: var(--color-4);
        font-size: 0.7291666666666666VW;
        font-weight: 500;
        padding-left: 1.09375VW;
        padding-right: 1vw;
        margin-right:2vw;
    }


#select-role {
  height: 2.5vw;
  width: 14.716667vw;
  border: 0.052083vw solid var(--color-2);
  border-radius: 0vw 1.5vw 1.5vw 0vw;
  color: var(--color-4);
  font-size: 0.7291666666666666vw;
  font-weight: 500;
  padding-left: 1.09375vw;
  display: flex;
  align-items: center;
  background-image: url("../../assets/blueflecha.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 12.6vw;
}
#select-role.rounded {
  border-radius: 0vw 1.5vw 0vw 0vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0.052083vw solid var(--color-2);
    border-bottom: 0;
}#select-ch:checked ~ .options-cont {
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 12.3vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}#select-ch:checked ~ .options-cont , #select-ch2:checked ~ .options-cont, #select-ch3:checked ~ .options-cont , #select-ch4:checked ~ .options-cont , #select-ch5:checked ~ .options-cont  , #select-ch6:checked ~ .options-cont , #select-ch7:checked ~ .options-cont{
    display: flex;
    position: absolute;
    padding: 0.573vw 1.25vw;
    flex-direction: column;
    background-color: white;
    width: 13.32vw;
    border-left: 0.052083vw solid var(--color-2);
    border-right: 0.052083vw solid var(--color-2);
    border-top: 0;
    border-bottom: 0.052083vw solid var(--color-2);
}
   
.buscador input {
    width: 15.312500000000002VW;
    border: none !important;
    border-radius: none !important;
    height: 1.5625VW !important;
    color: #8D8D8D;
}
.mtbtn{
        margin-left:38vw;
    margin-top: 1.4vw;
    margin-bottom: 1vw;
}

.optionsbl{
    margin-top: 1vw;
}

.col1{
 
     
    width: 5vw;
    text-align: center;
   
}
.col2{

     
      width: 5vw;
    text-align: center;
}
.col3{
   
     
    width: 56vw;
    
}
.cl3t{
    text-align: center;
}

.col3 p{
    padding-left: 1vw;
}
.col4{
   
    
      width: 5vw;
    text-align: center;
}


.header-bc p{
    margin-right: 1px ;
     color: aliceblue;
    background: var(--color-2);
    margin-bottom: 0vw;
    font-weight: 600;
    padding: 0.5vw 0vw;
    
}

.bloq-item{
  border-bottom: .2vw solid var(--color-4);
   color: var(--color-4);
  
}

.bloq-item h2:hover{
    color: var(--color-1);
}

.bloques-cont{
    width: 71vw;
    margin-bottom: 5vw;
}

h2.cp{
    cursor: pointer;
}
.caja1, .caja2{
    width: 50%;
}

.col3 img{
        width: 72%;
    padding: 0.5vw 3.5vw;
}

.col3 iframe{
    padding: 0.5vw 3.5vw;
}
 
</style>



