<template>
<div id="HeaderSec">
        <div class="dflx">
            <div class="titles">
               
            <div v-if="historyOption == 'Perzonalizacion'">
                <h3>PERSONALIZACIÓN</h3>
                <p>Selección de Perfiles de Color</p>
            </div>
            
            <div v-else-if="historyOption == 'Usuarios'">
                <div v-if="historyOptionUsr == 'Default'">
                     <h3>USUARIOS </h3>  <p>Accesos permitidos al administrador</p>
                </div>
                <div v-if="historyOptionUsr == 'Add'">
                     <h3>USUARIOS </h3>  <p>Agregar nuevo usuario</p>
                </div>
                <div v-if="historyOptionUsr == 'Edit'">
                     <h3>USUARIOS </h3>  <p>Editar usuario</p>
                </div>
               
            </div>
             <div v-else-if="historyOption == 'Default'">
                <div v-if="historyOptionUsr == 'Default'">
                     <h3>USUARIOS </h3>  <p>Accesos permitidos al administrador</p>
                </div>
                <div v-if="historyOptionUsr == 'Add'">
                     <h3>USUARIOS</h3>  <p>Agregar nuevo usuario</p>
                </div>
                <div v-if="historyOptionUsr == 'Edit'">
                     <h3>USUARIOS </h3>  <p>Editar usuario</p>
                </div>
               
            </div>
            
           
             <div  v-else-if="historyOption == 'BLOG'">
                <div v-if="historyOptionBlo == 'Default'">
                     <h3>BLOG </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionBlo == 'Add'">
                     <h3>BLOG </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionBlo == 'Edit'">
                     <h3>BLOG </h3>  <p>Editar</p>
                </div>
            </div>

               <div  v-else-if="historyOption == 'TAG'">
                   
                <div v-if="historyOptionTg == 'Default'">
                     <h3>ETIQUETAS </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionTg == 'Add'">
                     <h3>ETIQUETAS </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionTg == 'Edit'">
                     <h3>ETIQUETAS </h3>  <p>Editar</p>
                </div>
            </div>
            <div  v-else-if="historyOption == 'BLOGCAT'">
                   
                <div v-if="historyOptionBct == 'Default'">
                     <h3>CATEGORÍA </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionBct == 'Add'">
                     <h3>CATEGORÍA </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionBct == 'Edit'">
                     <h3>CATEGORÍA </h3>  <p>Editar</p>
                </div>
            </div>
             <div  v-else-if="historyOption == 'PROJCAT'">
                   
                <div v-if="historyOptionPct == 'Default'">
                     <h3>CATEGORÍA </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionPct == 'Add'">
                     <h3>CATEGORÍA </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionPct == 'Edit'">
                     <h3>CATEGORÍA </h3>  <p>Editar</p>
                </div>
            </div>

              <div  v-else-if="historyOption == 'PROJ'">
                   
                <div v-if="historyOptionPrj == 'Default'">
                     <h3>PORTAFOLIO </h3>  <p>Lista proyectos</p>
                </div>
                <div v-if="historyOptionPrj == 'Add'">
                     <h3>PORTAFOLIO </h3>  <p>Agregar nuevo proyecto </p>
                </div>
                <div v-if="historyOptionPrj == 'Edit'">
                     <h3>PORTAFOLIO </h3>  <p>Editar proyecto</p>
                </div>
            </div>

            <div  v-else-if="historyOption == 'historias'">
                   
                <div v-if="historyOptionHts == 'Default'">
                     <h3>HISTORIAS DE ÉXITO </h3>  <p>Listado</p>
                </div>
                <div v-if="historyOptionHts == 'Add'">
                     <h3>HISTORIAS DE ÉXITO </h3>  <p>Agregar nuevo</p>
                </div>
                <div v-if="historyOptionHts == 'Edit'">
                     <h3>HISTORIAS DE ÉXITO</h3>  <p>Editar historias de éxito</p>
                </div>
            </div>


            <div  v-else-if="historyOption == 'colaboradores'">
                   
                <div v-if="historyOptionClb == 'Default'">
                     <h3>COLABORADORES </h3>  <p>Lista registrados</p>
                </div>
                <div v-if="historyOptionClb == 'Add'">
                     <h3>COLABORADORES </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionClb == 'Edit'">
                     <h3>COLABORADORES </h3>  <p>Editar</p>
                </div>
            </div>

            <div  v-else-if="historyOption == 'videos'">
                <div v-if="historyOptionVdo == 'Default'">
                     <h3>VIDEOS </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionVdo == 'Add'">
                     <h3>VIDEOS </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionVdo == 'Edit'">
                     <h3>VIDEOS </h3>  <p>Editar</p>
                </div>
            </div>

            <div  v-else-if="historyOption == 'VDOCAT'">
                <div v-if="historyOptionVdo == 'Default'">
                     <h3>CATEGORÍA </h3>  <p>Lista disponibles</p>
                </div>
                <div v-if="historyOptionVdo == 'Add'">
                     <h3>CATEGORÍA </h3>  <p>Agregar nueva </p>
                </div>
                <div v-if="historyOptionVdo == 'Edit'">
                     <h3>CATEGORÍA </h3>  <p>Editar</p>
                </div>
            </div>
            <div  v-else-if="historyOption == 'paquetes'">
                <div v-if="historyOptionSrv == 'Default'">
                     <h3>SERVIDORES </h3>  <p>Administrar paquetes</p>
                </div>
                <div v-if="historyOptionSrv == 'addPaquete'">
                     <h3>SERVIDORES </h3>  <p>Agregar paquete </p>
                </div>
                <div v-if="historyOptionSrv == 'editPaquete'">
                     <h3>SERVIDORES </h3>  <p>Editar paquete</p>
                </div>
            </div>
            <div  v-else-if="historyOption == 'clientes'">
                <div v-if="historyOptionCli == 'Default'">
                     <h3>CLIENTES </h3>  <p>Administrar Clientes</p>
                </div>
                <div v-if="historyOptionCli == 'addClient'">
                     <h3>CLIENTES </h3>  <p>Agregar Cliente </p>
                </div>
                <div v-if="historyOptionCli == 'editClient'">
                     <h3>CLIENTES </h3>  <p>Editar Cliente</p>
                </div>
            </div>
            
            <div v-else>
                <h3>{{historyOption}}</h3>
                <p>Opción aun no disponible.</p>
            </div>
            
             </div>
             <div class="user-dv dflx">
                 <div >
                     <h4>{{identity.name | splitUp }} {{identity.lastname | splitUp}}</h4>
                     <button @click="cerrarSesion()">Cerrar Sesión</button>
                 </div>
                 <div v-if="statusicon=='success'" class="icon-user">
                     <img :src="this.srcImg(icon)" alt="icono usuario">
                 </div>
                 <div v-else class="icon-user">
                     <img :src="icondefault" alt="icono usuario">
                 </div>
               

                

                

                
              

             </div>
        </div>
       
        <hr class="hrhd">

       

        <div v-if="viewModal" class="modal_view">
           
            <div id="modal_logout">
                <div class="body">
                     <img src="../assets/logout.png" alt="icono alerta">
            <h3 > <span>Cerrar Sesión</span></h3>
                </div>
           
            <div class="modal_log_btns dflx">
                <p @click="logout()">Aceptar</p>   <p @click="cancelar()">Cancelar</p>
            </div>
          </div>
        </div>
</div>
       
       
    
</template>
<script>

let urlpath = require('../global/url')
import {mapActions} from 'vuex';
import icondefault from '../assets/icon-user.png'
export default {
  name: 'HeaderSec',
   data(){
       return{
         message:'',
         status:'',
         viewModal:false,
         urlpath:urlpath,
         icondefault: icondefault,
         statusimg:'success'
       }
   },
    async created(){
    await this.getIdentity()

    let payload={
            id:this.identity.sub,
            option: "user",
        }
          this.getImage(payload);
     
},
    computed:{
        historyOption(){
            return this.$store.getters["main/getHistoryOption"]
        },
         icon(){
            return this.$store.getters["main/img"]
        },
          statusicon(){
            return this.$store.getters["main/statusimg"]
        },
         historyOptionUsr(){
            return this.$store.getters["usuarios/getHistoryOption"]
        },
          historyOptionTg(){
            return this.$store.getters["tag/getHistoryOption"]
        },
         historyOptionBct(){
            return this.$store.getters["blog_category/getHistoryOption"]
        },
          historyOptionPct(){
            return this.$store.getters["project_category/getHistoryOption"]
        },
          historyOptionPrj(){
            return this.$store.getters["project/getHistoryOption"]
        },
         historyOptionClb(){
            return this.$store.getters["colaboradores/getHistoryOption"]
        },
      
           historyOptionBlo(){
            return this.$store.getters["blog/getHistoryOption"]
        },
            historyOptionHts(){
            return this.$store.getters["historias/getHistoryOption"]
        },
        historyOptionVdo(){
            return this.$store.getters["videos/getHistoryOption"]
        },
        historyOptionSrv(){
            return this.$store.getters["servidores/getHistoryOption"]
        },
        historyOptionCli(){
            return this.$store.getters["clients/getHistoryOption"]
        },
         identity() {
      return this.$store.getters['admin/getIdentity'];
    },
    },
    methods:{
         ...mapActions('main', ['getImage']),
        wait: function () {
        setTimeout(() => this.$router.go(), 200);
        },

         getIdentity: async function (){
          let response =  await this.$store.dispatch("admin/getData");
          return response
      },
    
        cerrarSesion(){
           this.viewModal = true;
        },
        cancelar(){
            this.viewModal = false;
        },
        logout: async function(){
            let response =  await this.$store.dispatch("admin/logout");
           this.wait()
        },

        srcImg:   function (img){
                let src = `${urlpath.url()}/user-img/${img}`
                
                this.status_img(img)
                 
                return src
        },

         status_img:async  function (img){
                 let payload ={
                    option:'user',
                    image:img
                }
                let response  =''

                try{
                     response =  await this.$store.dispatch("main/getStatusImage", payload);
                    if(response.data.status=='error'){
                        this.statusimg='error'
                    }else{
                        this.statusimg='success'
                    }
                }catch(err){
                    this.statusimg='error'
                }

               
               
    },

    },
     filters:{
     split: (value) => {
            if (!value) return '';
            value = value.toString();

          
            return  value.charAt(0).toUpperCase() 
          

        },
        splitUp: (value) => {
            if (!value) return '';
            value = value.toString();

            let splitStr = value.toLowerCase().split(' ');
           

           
             return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     

        },
        splitName: (value) => {
            
            if (!value) return '';
            value = value.toString();
            

            let splitStr = value.toLowerCase().split(' ');
            let len = splitStr.length
            
            if(len == 1){
              return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);     
            }

            return  splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1) + ' ' + splitStr[1].charAt(0).toUpperCase() + splitStr[1].substring(1);  ;  

          

        },

        
 }
}
</script>

<style scoped>



    #HeaderSec{
        margin-top: 5.46875VW;
    }

    hr.hrhd{
        width: 70.72916666666667VW;
        margin-left: 0px;
        border-bottom: .2px solid #B1B1B1;
       margin-top: 1.6145833333333335VW;
       margin-bottom: 2.9166666666666665VW;
    }

.titles{
    width: 39.375VW;
}
    .titles h3{
        color: var(--color-4);
        font-weight: 600;
        font-size: 1.3020833333333335VW;
        margin: 0vw;
        text-transform: uppercase;
    }.titles p{
        color: var(--color-4);
        margin: 0px;
        font-size: 0.8333333333333334VW;
    }

    .user-dv{
        margin-left: 10vw;
    }

    .user-dv h4{
        color: var(--color-2);
        margin: 0vw;
        font-size: 0.9375VW;
        margin-top: .5vw;
            text-align: right;
    }
     .user-dv button{
        color: var(--color-1);
        text-decoration: underline;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 0.7291666666666666VW;
        margin-left: 3.2vw;
        padding: 0vw;
    }
    .icon-user{
        margin-left: 0.8854166666666666VW;
        width: 3.6458333333333335VW;
        height: 3.6458333333333335VW;
   overflow: hidden;
        border-radius: 2vw;
    }.icon-user img{
         width: 3.6458333333333335VW;
        height: 3.6458333333333335VW;
    }





</style>