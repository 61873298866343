<template>
    <section>
        <div class="contBox">


            <h3>Editar Paquete</h3>

            <form >

                <div>
                    <label for="paqName">Nombre del paquete</label>
                    <input v-model="added.nombre" placeholder="Elige un nombre" minlength="6" required id="paqName" type="text">
                </div>
                <div>
                    <label for="paqName">Periodo de tiempo</label>
                    <div :class="{valid: added.periodo !== ''}" @click="showSelector = !showSelector" class="selector">
                        <p :class="{ filled: added.periodo !== '' }">{{ added.periodo === '' ? 'Elige un periodo' : added.periodo }}</p>
                        <img src="../../assets/blueflecha.svg" alt="">
                        <div v-if="showSelector" class="selectorOptions">

                            <ul>
                                <li @click="added.periodo = 'Mensual'">
                                    Mensual
                                </li>
                                <li @click="added.periodo = 'Trimestral'">
                                    Trimestral
                                </li>
                                <li @click="added.periodo = 'Semestral'">
                                    Semestral
                                </li>
                                <li @click="added.periodo = 'Anual'">
                                    Anual
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div>
                    <label for="paqSpace">Espacio en Mb</label>
                    <div class="inputValueSymbol">
                        <input v-model="added.espacio" min="0" required id="paqSpace" type="number" placeholder="255"> <span>Mb</span>
                    </div>
                </div>
                <div>
                    <label for="paqBandW">Ancho de banda en Mb/s</label>
                    <div class="inputValueSymbol">
                        <input v-model="added.ancho_banda" min="0" required id="paqBandW" type="number" placeholder="20"> <span>Mb/s</span>
                    </div>
                </div>
                <div>
                    <label for="paqPrice">Precio del paquete</label>
                    <div class="inputValueSymbol">
                        <input v-model="added.precio" min="0" placeholder="299" required id="paqPrice" type="number"> <span>$</span>
                    </div>
                </div>
                <div>
                    <label for="paqDescr">Descripción</label>
                    <div class="inputValueSymbol">
                        <input v-model="added.descripcion" min="0" placeholder="299" required id="paqDescr" type="number"> <span>$</span>
                    </div>
                </div>

            </form>

            <div class="action">
                <button class="outline" @click="wait">
                    <p>Cancelar </p> <img src="../../assets/SubtractFill.svg" alt="">
                </button>
                <button @click="updatePaquete(added.nombre, added.periodo, added.espacio, added.ancho_banda, added.precio, added.descripcion)" class="pink">
                    <p>Actualizar paquete </p> <img src="../../assets/Subtract.svg" alt="">
                </button>
            </div>

            <div v-if="status !=='' && message" class="status_messages">
                <div  v-if="status ==='error'" class="msg msg_error">
                    <p>{{message}}</p>
                </div>
            </div>


            
        </div>


        <div v-if="viewModal" class="modal_view">
           
           <div id="modal_edit">
               <div class="body dflx">
                          <h3>Paquete actualizado </h3>
                          <img src="../../assets/add.png" alt="icono alerta">
               </div>
          
           <div class="modal_edit_btns dflx">
               <p @click="viewModal=false" class="otro">Aceptar</p>
           </div>
         </div>
       </div>


    </section>
</template>

<script>
import { mapActions } from 'vuex';
import addPaquete from './addPaquete.vue'
import editPaquete from './editPaquete.vue'
export default {
    name: 'AñadirServ',
    components: {
        addPaquete,
        editPaquete,
    },
    data() {
        return {
            showSelector: false,
             viewModal:false,
             status:'',
             message:'',
        }
    },
    computed: {
        added(){
            const data =this.$store.getters["paquete/getAdded"]
            return data;
        },
    },
    methods: {
        ...mapActions('paquete', ['setAddedPqt']),
        wait: function () {
            setTimeout(() => this.$router.go(), 200);
        },
        delStatus: function () {
            setTimeout(() => {
                  this.status =''
                 this.message =''
            }
            ,2000);
        },
      async  updatePaquete(nombre, periodo, espacio, ancho_banda, precio, descripcion){
            this.status =''
                 this.message =''

                const response = await this.$store.dispatch("paquete/editItemPqt",  {option:'paquete', id: this.added.id, item: { nombre, periodo, espacio, ancho_banda, precio, descripcion } });
                    console.log(response)
                if(!response?.result){
                    this.status='error'
                    this.message= "Ha ocurrido un error"
                    this.delStatus()
                }else{ // success
                    this.setAddedPqt(response.result )
                    this.viewModal=true
                } 
        }
    }
}
</script>


<style scoped>
* {
    margin: 0;
    padding: 0;
}

h3 {
    color: #acacac;

    font-size: 1.2375VW;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contBox {
    display: flex;
    padding: 1.7187500000000002VW 1.9791666666666665VW;
    flex-direction: column;
    gap: 1.2VW;
    border-radius: 1.25VW;
    border: 0.5px solid #E6E6E6;
    background: #FFF;
}


.action {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}

.action button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 12vw;
    padding: 0.7vw 1.2vw;
    border-radius: 50vw;
    border: none;
    cursor: pointer;
}

.action button p {

    font-size: 0.725VW;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pink {
    background: var(--color-1);
    color: #FFF;

}

.outline {
    border: solid 0.1vw var(--color-4) !important;
    background: transparent;
    color: var(--color-1);
}

form {
    width: 50vw;
    display: flex;
    flex-wrap: wrap;
    gap: 0.8vw 2vw;
}

form>div {
    width: 19.416666666666668VW;
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
}

form>div label {
    color: #7948FF;

    font-family: var(--Poppins);
    font-size: 0.825VW;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

form>div input {
    font-weight: 500;
    font-family: var(--Poppins);
    padding: 0.7166666666666667VW 0.825VW;
    font-size: 0.7208333333333333VW;
    border-radius: 0;
    border: solid #b8b8b8 0.1vw;
    transition: 300ms all;
}

input:focus {
    border-color: #7948FF;
}

input:valid {
    border-color: greenyellow;
}

input:invalid {
    border-color: #b8b8b8;
}

input::placeholder {
    color: #cfcfcf;
}

.selector {
    position: relative;
    font-weight: 500;
    font-family: var(--Poppins);
    padding: 0.7166666666666667VW 0.825VW;
    font-size: 0.7208333333333333VW;
    border-radius: 0;
    border: solid #b8b8b8 0.1vw;
    transition: 300ms all;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.selector p {
    user-select: none;
    color: #cfcfcf;
}

.selector img {
    width: 1vw;
}

.inputValueSymbol {
    width: 22.216667VW;
}

.inputValueSymbol input {
    width: 17.716666666666668VW;

}

.inputValueSymbol span {
    width: 20%;
    color: #acacac;
    font-size: 0.7vw;
}

.selectorOptions {
    position: absolute;
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: solid #b8b8b8 0.1vw;
    border-top: none;
    margin-left: -0.87vw;
    height: auto;
    top: 0;
    transform: translateY(2.5vw);
}

li {
    font-weight: 500;
    font-family: var(--Poppins);
    padding: 0.7166666666666667VW 0.825VW;
    font-size: 0.7208333333333333VW;
    border-radius: 0;
    transition: 300ms all;
    list-style: none;
}

li:hover {
    background-color: #f3f3f3;
}

.filled {
    color: #000000 !important;
    font-weight: 900;
}

.valid {
    border-color: greenyellow;
}
</style>